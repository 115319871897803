import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  initialize,
  reset,
  formValueSelector
} from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetMaterial,
  UpdateMaterial,
  CreateMaterial,
  GetMaterialCategories,
  CreateMaterialCategories,
  RemoveMaterialCategories
} from '../../infra/requests/MaterialsRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import SwitchInput from '../../components/generic/inputs/SwitchInput';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { QuantityTypes } from '../../infra/services/utils/QuantityTypes';
import { Row, Col } from 'antd';
import ManageFormHeader from '../../components/base/ManageFormHeader';

const validations = FormValidator.make({
  name: 'required',
  email: 'required',
  category: 'required',
  categoryName: 'required',
  subcategory: 'required',
  quantity: 'required',
  quantityType: 'required',
  containerFormulaNumber: 'required',
  stockAlert: 'required'
});

class ManageMaterialsPage extends Component {
  state = {
    isNew: false,
    isNewCategory: false,
    loading: true,
    categories: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    await this.updateCategoriesMaterials();
    if (params.id) {
      const result = await GetMaterial(params.id);
      dispatch(
        initialize('manage_materials_form', {
          ...result.data,
          category: result.data.category?._id
        })
      );
      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  updateCategoriesMaterials = async () => {
    const { data } = await GetMaterialCategories();
    this.setState({ categories: data });
  };

  createMaterialsCategories = async (categoryName) => {
    await CreateMaterialCategories({ name: categoryName });
    const { data } = await GetMaterialCategories();
    this.setState({ categories: data });
  };

  removeMaterialCategory = async (category) => {
    await RemoveMaterialCategories(category._id);
    await this.updateCategoriesMaterials();
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch
      } = this.props;
      const { isNew, isNewCategory } = this.state;
      let request;

      if (isNewCategory) {
        const newCategoryRequest = await CreateMaterialCategories({
          name: values.categoryName
        });
        request = isNew
          ? await CreateMaterial({
              ...values,
              category: newCategoryRequest.data._id
            })
          : await UpdateMaterial(params.id, {
              values,
              category: newCategoryRequest.data._id
            });
      } else {
        request = isNew
          ? await CreateMaterial(values)
          : await UpdateMaterial(params.id, values);
      }

      if (request.success) {
        this.setState({ loading: false });
        if (createNewAfter) {
          if (isNewCategory) {
            await this.updateCategoriesMaterials();
          }
          dispatch(reset('manage_materials_form'));
          return history.push('/materials/add');
        } else {
          return history.push('/materials');
        }
      }
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/materials');
  };

  render() {
    const { handleSubmit, formula, pristine } = this.props;
    const { isNew, loading, categories, isNewCategory } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Materiais', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Nome"
              placeholder="Nome"
            />
            <Row>
              <Col xs={24} md={8}>
                <Field
                  component={SwitchInput}
                  name="isNewCategory"
                  label="É uma nova categoria?"
                  input={{
                    value: isNewCategory,
                    onChange: (value) =>
                      this.setState({ isNewCategory: value })
                  }}
                  style={{ width: '300px' }}
                />
              </Col>
              <Col xs={24} md={16}>
                {isNewCategory ? (
                  <Field
                    component={TextInput}
                    name="categoryName"
                    type="text"
                    label="Nome da categoria"
                    placeholder="Escreva o nome da categoria"
                  />
                ) : (
                  <Field
                    component={SelectInput}
                    name="category"
                    data={categories}
                    label="Categoria"
                    placeholder="Escolha a categoria..."
                    asyncRemove={this.removeMaterialCategory}
                  />
                )}
              </Col>
            </Row>
            <Field
              component={TextInput}
              name="subcategory"
              type="text"
              label="Subcategoria"
              placeholder="Subcategoria"
            />
            <Field
              component={TextInput}
              name="quantity"
              type="number"
              label="Quantidade"
              placeholder="Quantidade"
            />
            <Field
              component={SelectInput}
              name="quantityType"
              data={QuantityTypes}
              label="Tipo de quantidade"
              placeholder="Escolha o tipo de quantidade..."
            />
            <Field
              component={SwitchInput}
              name="consumable"
              label="É um consumível?"
            />
            <Field
              component={TextInput}
              name="containerFormulaNumber"
              type="number"
              label="Valor necessário para encher um contentor"
              placeholder="Valor necessário para encher um contentor"
            />
            <Field
              component={TextInput}
              name="stockAlert"
              type="number"
              label="Alerta de stock"
              placeholder="Quantidade minima para criar alerta de stock"
            />
            <div style={{ marginTop: '40px' }}>
              <TextInput
                disabled
                type="number"
                label="Quantidade de stock em contentores"
                input={{ value: formula }}
                meta={{}}
              />
            </div>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageMaterialsPage = reduxForm({
  form: 'manage_materials_form',
  validate: validations
})(ManageMaterialsPage);

const selector = formValueSelector('manage_materials_form'); // <-- same as form name

const mapStateToProps = (state) => {
  const quantityValue = selector(state, 'quantity');
  const containerFormulaValue = selector(state, 'containerFormulaNumber');
  let formula = 0;
  if (quantityValue !== undefined && containerFormulaValue !== undefined) {
    formula = (quantityValue * 1) / containerFormulaValue;
  }
  return {
    material: state.material,
    formula
  };
};

export default withLocalize(connect(mapStateToProps)(ManageMaterialsPage));
