import client from '../config/AxiosConfig';

export const GetVehicle = async (id) => await client.get(`/vehicles/${id}`);

export const GetAllVehicles = async () => await client.get('/vehicles');

export const GetVehicles = async (currentPage, limit, filters, sorter) =>
  await client.get(
    `/vehicles/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const UpdateVehicle = async (id, data) =>
  await client.put(`/vehicles/${id}`, data);

export const CreateVehicle = async (data) =>
  await client.post('/vehicles', data);

export const DeleteVehicle = async (id) =>
  await client.delete(`/vehicles/${id}`);

export const GetVehicleEvents = async (
  vehicleId,
  currentPage,
  limit,
  filters,
  sorter
) =>
  await client.get(
    `/vehicles/${vehicleId}/events/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const GetVehiclesPDF = async () =>
  await client.get('/vehicles/download-pdf', {
    responseType: 'blob'
  });
export const GetVehiclesCSV = async () =>
  await client.get('/vehicles/download-csv', { responseType: 'blob' });
