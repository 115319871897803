export const EventPlateTypes = [
  { _id: 'laminated', name: 'Laminada' },
  { _id: 'vinilLined', name: 'Forrada a Vinil' },
  { _id: 'MDF', name: 'MDF' }
];

export const getEventPlateType = (value) => {
  switch (value) {
    case 'laminated':
      return 'Laminada';
    case 'vinilLined':
      return 'Forrada a Vinil';
    case 'MDF':
      return 'MDF';
    default:
      break;
  }
};
