import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import SwitchInput from '../../components/generic/inputs/SwitchInput';
import DateInput from '../../components/generic/inputs/DateInput';

class AlertsFilters extends Component {
  state = {
    showIgnored: false,
    startDate: '',
    endDate: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { showIgnored, startDate, endDate } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={5} xl={5}>
            <SwitchInput
              input={{
                value: showIgnored,
                onChange: (event) =>
                  this.onInputChange('showIgnored', !showIgnored, 1000)
              }}
              meta={{ valid: true }}
              label="Mostrar ignorados?"
            />
          </Col>

          <Col xs={24} md={8} xl={6}>
            <DateInput
              input={{
                value: startDate,
                onChange: (event) =>
                  this.onInputChange('startDate', event, 1000)
              }}
              label="Data da incidência"
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={8} xl={6}>
            <DateInput
              input={{
                value: endDate,
                onChange: (event) => this.onInputChange('endDate', event, 1000)
              }}
              label="Fim da incidência"
              meta={{ valid: true }}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default AlertsFilters;
