import React, { Component } from 'react';
import { Field, reduxForm, initialize, reset } from 'redux-form';
import {
  GetContributor,
  UpdateContributor,
  CreateContributor
} from '../../infra/requests/ContributorsRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { Departments } from '../../infra/services/utils/Departments';
import SwitchInput from '../../components/generic/inputs/SwitchInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import DateInput from '../../components/generic/inputs/DateInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import ManageFormHeader from '../../components/base/ManageFormHeader';

const validations = FormValidator.make({
  code: 'required',
  name: 'required',
  firstDayAtCompany: 'required',
  NIF: 'required',
  citizenCard: 'required',
  citizenCardValidation: 'required'
});

class ManageContributorsPage extends Component {
  state = {
    isNew: false,
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    if (params.id) {
      const result = await GetContributor(params.id);
      dispatch(initialize('manage_contributors_form', result.data));
      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch
      } = this.props;
      const { isNew } = this.state;

      if (values.photo && !values.photo.blob) {
        delete values.photo;
      }

      // format dates to ISO
      const payload = FlattenToFormData(values);

      const request = isNew
        ? await CreateContributor(payload)
        : await UpdateContributor(params.id, payload);

      if (request.success) {
        this.setState({ loading: false });
        if (createNewAfter) {
          dispatch(reset('manage_contributors_form'));
          return history.push('/contributors/add');
        } else {
          return history.push('/contributors');
        }
      }
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/contributors');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Colaboradores', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ marginBottom: '50px' }}
          >
            <Field
              component={ImageInput}
              name="photo"
              type="text"
              label="Foto"
              width="100%"
            />
            <Field
              component={TextInput}
              name="code"
              type="text"
              label="Código"
              placeholder="Escreva o código do colaborador"
            />
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Nome"
              placeholder="Escreva o nome do colaborador"
            />
            <Field
              component={TextInput}
              name="email"
              type="text"
              label="Email"
              placeholder="Escreva o email do colaborador"
            />
            <Field
              component={TextInput}
              name="address"
              type="text"
              label="Morada"
              placeholder="Escreva a morada do colaborador"
            />
            <Field
              component={DateInput}
              name="birthday"
              type="text"
              placeholder="Escolha o dia de aniversário"
              label="Dia de aniversário"
            />
            <Field
              component={TextInput}
              name="phone"
              type="text"
              label="Número de telemóvel"
              placeholder="Escreva o número de telemóvel"
            />
            <Field
              component={SelectInput}
              name="department"
              label="Departamento"
              placeholder="Escolha o departamento.."
              data={Departments}
            />
            <Field
              component={TextInput}
              name="NIF"
              type="text"
              label="NIF"
              placeholder="Escreva o número de identificação fiscal"
            />
            <Field
              component={TextInput}
              name="citizenCard"
              type="text"
              label="Número de cartão de cidadão"
              placeholder="Escreva o número do cartão de cidadão"
            />
            <Field
              component={DateInput}
              name="citizenCardValidation"
              placeholder="Cartão de cidadão válido até"
              label="Escolha o dia até que o cartão é valido"
            />
            <Field
              component={SwitchInput}
              name="intern"
              label="Colaborador interno?"
            />
            <Field
              component={DateInput}
              name="firstDayAtCompany"
              type="text"
              placeholder="Dia de entrada na empresa"
              label="Escolha o dia em que o colaborador entrou na empresa"
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageContributorsPage = reduxForm({
  form: 'manage_contributors_form',
  validate: validations
})(ManageContributorsPage);

export default ManageContributorsPage;
