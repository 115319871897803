export default [
  {
    type: 'menu',
    name: 'Dashboard',
    to: '/',
    icon: 'read',
    exact: true
  },
  {
    type: 'menu',
    name: 'Eventos',
    to: '/events',
    icon: 'carry-out',
    exact: true
  }
];
