import React, { useEffect, useState } from 'react';
import { Row, Popconfirm, Icon } from 'antd';
import Table from '../../../../components/generic/table/Table';
import { getVehiclesCategoryTypes } from '../../../../infra/services/utils/VehiclesCategoryTypes';
import { GetAllVehicles } from '../../../../infra/requests/VehiclesRequests';
import {
  change,
  FormSection,
  Field,
  formValueSelector,
  arrayRemove
} from 'redux-form';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import { connect } from 'react-redux';
import { TableButton } from '../../../../styles/BasicStyles';

let VehiclesTable = ({ fields, objectName, dispatch, vehicles }) => {
  const [allVehicles, setAllVehicles] = useState([]);
  const [hasFetchVehicles, setHasFetchVehicles] = useState(false);

  useEffect(() => {
    async function fetchAllVehicles() {
      const vehiclesRequest = await GetAllVehicles();
      setAllVehicles(vehiclesRequest.data);
      setHasFetchVehicles(true);
    }
    if (!hasFetchVehicles) {
      fetchAllVehicles();
    }
  }, [hasFetchVehicles]);

  const execAfterChangeVehicle = (index, value, item) => {
    const selected = allVehicles.find((vehicle) => vehicle._id === value);
    dispatch(
      change('manage_events_form', `vehicles[${index}]`, {
        vehicle: {
          _id: selected._id,
          registration: selected.registration,
          brand: selected.brand,
          category: selected.category
        },
        contributor: {
          _id: selected.contributor?._id,
          name: selected.contributor?.name
        }
      })
    );
  };

  const columns = [
    {
      title: 'Matricula',
      dataIndex: 'vehicle',
      render: (value, item, index) => {
        const availableVehicles =
          item.vehicle._id !== ''
            ? [
                item.vehicle,
                ...allVehicles.filter(
                  (vehicle) =>
                    !vehicles.some(
                      (rowVehicle) => rowVehicle.vehicle._id === vehicle._id
                    )
                )
              ]
            : allVehicles.filter(
                (vehicle) =>
                  !vehicles.some(
                    (rowVehicle) => rowVehicle.vehicle._id === vehicle._id
                  )
              );
        return (
          <FormSection name={`${objectName}[${index}].vehicle`}>
            <Field
              component={SelectInput}
              name={'_id'}
              dataLabel={'registration'}
              execAfterChange={(val) =>
                execAfterChangeVehicle(index, val, item)
              }
              placeholder="Escolha o veículo..."
              data={availableVehicles}
            />
          </FormSection>
        );
      }
    },
    {
      title: 'Marca',
      dataIndex: 'vehicle.brand'
    },
    {
      title: 'Categoria',
      dataIndex: 'vehicle.category',
      render: getVehiclesCategoryTypes
    },
    {
      title: 'Colaborador',
      dataIndex: 'contributor',
      render: (data) => (data && typeof data === 'object' ? data.name : '')
    },
    {
      title: '',
      render: (value, item, index) => {
        return (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover o veículo?"
                onConfirm={() =>
                  dispatch(arrayRemove('manage_events_form', 'vehicles', index))
                }
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <>
      <Row style={{ marginTop: '25px', marginBottom: '10px' }}>
        <BaseButton
          type="default"
          icon="plus"
          text="Adicionar campo"
          onClick={() => {
            if (
              vehicles.length === 0 ||
              (vehicles.length > 0 && vehicles[0].vehicle._id !== '')
            ) {
              return fields.unshift({
                vehicle: { _id: '', brand: '', registration: '', category: '' }
              });
            }
          }}
        />
      </Row>
      <Row>
        <Table
          columns={columns}
          currentPage={1}
          pageSize={vehicles.length}
          loading={false}
          rows={vehicles}
          showHeader={true}
          emptyIcon="solution"
          emptyText={'Nenhum colaborador tem veiculos.'}
          total={vehicles.length}
          rowKey={(record) => record.vehicle._id}
        />
      </Row>
    </>
  );
};

const selector = formValueSelector('manage_events_form');

VehiclesTable = connect((state) => {
  const { vehicles } = selector(state, 'hasFurniture', 'vehicles');
  return {
    vehicles
  };
})(VehiclesTable);

export default VehiclesTable;
