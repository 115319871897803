import { SAVE_USER } from '../ActionsType';

export const SaveUser = user => {
  return {
    type: SAVE_USER,
    user
  };
};

export const DeleteUser = () => {
  return {
    type: SAVE_USER,
    user: {}
  };
};
