import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm, SectionTitle } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import NumberInput from '../../components/generic/inputs/NumberInput';
import { AddMaterialStock } from '../../infra/requests/MaterialsRequests';
import { connect } from 'react-redux';

const validations = FormValidator.make({
  quantity: 'required'
});

let AddMaterialsModal = ({
  material,
  open,
  handleSubmit,
  closeModal,
  closeModalAndUpdate,
  quantityValue,
  reset
}) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (fields) => {
    setLoading(true);
    try {
      const request = await AddMaterialStock(material._id, fields);
      if (request.success) {
        reset();
        closeModalAndUpdate();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleOnCancel = () => {
    reset();
    closeModal();
  };

  const getStockQuantity = () =>
    quantityValue ? quantityValue + material.quantity : material.quantity;

  return (
    <Modal
      visible={open}
      title="Adicionar stock"
      maskClosable={false}
      onCancel={handleOnCancel}
      footer={[
        <Button key="back" onClick={handleOnCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Gravar
        </Button>
      ]}
    >
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>Dados do material</SectionTitle>
        <Row>
          <Col xs={24}>Nome: {material.name}</Col>
        </Row>
        <Row>
          <Col xs={24}>
            Categoria: {material.category && material.category.name}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>Subcategoria: {material.subcategory}</Col>
        </Row>
        <Row>
          <Col xs={24}>Stock atual: {material.quantity}</Col>
        </Row>
        <Row>
          <Col xs={24} style={{ marginTop: '20px' }}>
            <Field
              component={NumberInput}
              name={'quantity'}
              label={'Adicionar stock ao material'}
              placeholder={'Escreva a quantidade a adionar...'}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} style={{ marginTop: '20px' }}>
            Quantidade do material resultante: {getStockQuantity()}
          </Col>
        </Row>
      </BaseForm>
    </Modal>
  );
};

AddMaterialsModal = reduxForm({
  form: 'add_materials_form',
  validate: validations
})(AddMaterialsModal);

const selector = formValueSelector('add_materials_form');

const mapStateToProps = (state) => {
  const quantityValue = selector(state, 'quantity');
  return {
    quantityValue
  };
};

export default connect(mapStateToProps)(AddMaterialsModal);
