import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { BaseForm, SectionTitle } from '../../../styles/BasicStyles';
import { DeleteContributorUnavailability } from '../../../infra/requests/ContributorsRequests';

const DetailsUnavailabilitiesModal = ({
  unavailabilities = [],
  selectedDate,
  contributor,
  open,
  closeModal,
  closeModalAndUpdate
}) => {
  const [loading, setLoading] = useState(false);

  const onRemove = async () => {
    setLoading(true);
    try {
      const request = await DeleteContributorUnavailability(contributor._id, {
        date: selectedDate
      });
      if (request.success) {
        closeModalAndUpdate();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      title="Detalhes das indisponibilidade"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="error"
          loading={loading}
          disabled={loading}
          onClick={() => onRemove()}
        >
          Apagar indisponibilidade
        </Button>
      ]}
    >
      <BaseForm>
        <SectionTitle>Dados do colaborador</SectionTitle>
        <Row>
          <Col xs={24}>Código: {contributor.code}</Col>
        </Row>
        <Row>
          <Col xs={24}>Nome: {contributor.name}</Col>
        </Row>
        <Row>
          <Col xs={24}>Contacto: {contributor.phone}</Col>
        </Row>
        <SectionTitle>Razão da indisponibilidades</SectionTitle>
        {unavailabilities.map((unavailability, index) => {
          return (
            <Row key={index}>
              <Col xs={24}> - {unavailability.type}</Col>
            </Row>
          );
        })}
      </BaseForm>
    </Modal>
  );
};

export default DetailsUnavailabilitiesModal;
