import React, { Component } from 'react';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/generic/buttons/BaseButton';
import {
  GetContributor,
  GetContributorUnavailability
} from '../../infra/requests/ContributorsRequests';
import UnavailabilityCalendarInput from './UnavailabilityCalendarInput';
import * as moment from 'moment';

class ContributorsCalendarPage extends Component {
  state = {
    loading: true,
    contributor: {},
    selectedDate: moment(),
    unavailabilities: []
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.updateContributor();
    await this.getUnavailability();
    this.setState({ loading: false });
  };

  onChangeDate = (date) => {
    this.setState({ selectedDate: date });
  };

  updateContributor = async () => {
    const {
      match: { params }
    } = this.props;
    if (params.id) {
      const result = await GetContributor(params.id);
      this.setState({ contributor: result.data });
    }
  };

  getUnavailability = async () => {
    try {
      const { contributor } = this.state;
      this.setState({ loading: true });
      const result = await GetContributorUnavailability(contributor._id);
      this.setState({ unavailabilities: result.data, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/contributors');
  };

  render() {
    const { selectedDate, unavailabilities, contributor, loading } = this.state;
    if (loading) return <SpinLoading size={'large'} />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Calendário</PageTitle>
            {`Colaboradores > ${contributor.name} > Calendário`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UnavailabilityCalendarInput
            value={selectedDate}
            label={'Indisponibilidade'}
            onChange={this.onChangeDate}
            unavailabilities={unavailabilities}
            createUnavailability={this.createUnavailability}
            deleteUnavailability={this.deleteUnavailability}
            contributor={contributor}
            updateUnavailability={this.getUnavailability}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ContributorsCalendarPage;
