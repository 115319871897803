import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  InputDiv,
  InputLabelDiv,
  CalendarInputField
} from '../../components/generic/inputs/InputStyles';
import * as moment from 'moment';
import { Icon, Row, Radio, Col, Select, Button } from 'antd';
import { StyledPopContainer, SectionTitle } from '../../styles/BasicStyles';
import { AlertColor } from '../../styles/Colors';
import AddUnavailabilitiesModal from './unavailabilityModals/AddUnavailabilitiesModal';
import DetailsUnavailabilitiesModal from './unavailabilityModals/DetailsUnavailabilitiesModal';
import { GetContributorUnavailabilityDate } from '../../infra/requests/ContributorsRequests';

const UnavailabilityCalendarInput = ({
  first,
  value,
  contributor,
  onChange,
  updateUnavailability,
  label,
  unavailabilities = []
}) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedUnavailabilities, setSelectedUnavailabilities] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const dateCellRender = (value) => {
    const date = unavailabilities.find((x) => value.isSame(x.date, 'day'));
    if (date?._id) {
      return (
        <StyledPopContainer
          style={{
            backgroundColor: AlertColor,
            color: 'white',
            border: `1px solid ${AlertColor}`,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => onClickDateInfo(contributor, value)}
        >
          Indisponivel
          <Icon
            style={{ marginLeft: 4, fontSize: '15px' }}
            type="info-circle"
          />
        </StyledPopContainer>
      );
    }
    return (
      <StyledPopContainer
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={() => onClickAddDate(contributor, value)}
      >
        <Icon style={{ marginRight: 4 }} type="plus" />
        Adicionar Ind.
      </StyledPopContainer>
    );
  };

  const onClickDateInfo = async (contributor, value) => {
    setSelectedDate(value);
    const selectedUnavailabilities = await GetContributorUnavailabilityDate(
      contributor._id,
      { date: value }
    );
    setSelectedUnavailabilities(selectedUnavailabilities.data);
    setIsDetailModalOpen(true);
  };

  const onClickAddDate = (contributor, value) => {
    setSelectedDate(value);
    setIsAddModalOpen(true);
  };

  const renderCustomHeader = ({ value, type, onChange, onTypeChange }) => {
    const displayYear = value.year();
    const year = moment().year();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }

    return (
      <>
        <Row type={'flex'} justify={'center'}>
          <SectionTitle>{value.format('MMMM, YYYY')}</SectionTitle>
        </Row>
        <Row type={'flex'} justify={'space-between'}>
          <Col sm={8}>
            <Row type={'flex'} justify={'start'}>
              <Button
                onClick={() => {
                  const now = value.subtract(1, 'month');
                  onChange(now);
                }}
              >
                <Icon type="arrow-left"></Icon>
                Mês anterior
              </Button>
              <Button
                onClick={() => {
                  const now = value.add(1, 'month');
                  onChange(now);
                }}
              >
                Próximo Mês
                <Icon type="arrow-right"></Icon>
              </Button>
            </Row>
          </Col>
          <Col sm={8}>
            <Radio.Group
              onChange={(e) => onTypeChange(e.target.value)}
              value={type}
            >
              <Radio.Button value="month">Mês</Radio.Button>
              <Radio.Button value="year">Ano</Radio.Button>
            </Radio.Group>
          </Col>
          <Col sm={8}>
            <Row type={'flex'} justify={'end'}>
              <Select
                dropdownMatchSelectWidth={false}
                className="my-year-select"
                onChange={(newYear) => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
                value={String(displayYear)}
              >
                {options}
              </Select>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <AddUnavailabilitiesModal
        selectedDate={selectedDate}
        open={isAddModalOpen}
        closeModal={() => setIsAddModalOpen(false)}
        contributor={contributor}
        closeModalAndUpdate={() => {
          setIsAddModalOpen(false);
          updateUnavailability();
        }}
        initialValues={{ startDate: selectedDate, endDate: selectedDate }}
      />
      <DetailsUnavailabilitiesModal
        selectedDate={selectedDate}
        open={isDetailModalOpen}
        closeModal={() => setIsDetailModalOpen(false)}
        contributor={contributor}
        closeModalAndUpdate={() => {
          setIsDetailModalOpen(false);
          updateUnavailability();
        }}
        unavailabilities={selectedUnavailabilities}
      />
      <InputDiv first={first}>
        <InputLabelDiv>{label}</InputLabelDiv>
        <CalendarInputField
          value={value}
          onSelect={onChange}
          onPanelChange={onChange}
          dateCellRender={dateCellRender}
          headerRender={renderCustomHeader}
        />
      </InputDiv>
    </>
  );
};

UnavailabilityCalendarInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default UnavailabilityCalendarInput;
