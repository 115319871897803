import React from 'react';
import { Popconfirm, Icon, Row } from 'antd';
import { Field, formValueSelector } from 'redux-form';
import Table from '../../../../components/generic/table/Table';
import { TableButton } from '../../../../styles/BasicStyles';
import TextInput from '../../../../components/generic/inputs/TextInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import { connect } from 'react-redux';

let AdvertisingVinylRefsTable = ({
  fields,
  objectName,
  advertisingVinylRef
}) => {
  const columns = [
    {
      title: 'Referencia',
      dataIndex: 'reference',

      render: (value, item, index) => (
        <Field
          component={TextInput}
          type="text"
          name={`${objectName}[${index}].reference`}
        />
      )
    },

    {
      title: 'Operações',
      render: (value, item, index) => (
        <React.Fragment>
          <TableButton error onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              placement="topRight"
              title="Tem a certeza que quer remover a referencia?"
              onConfirm={() => fields.remove(index)}
            >
              <Icon style={{ marginRight: 10 }} type="delete" />
              {'Remover'}
            </Popconfirm>
          </TableButton>
        </React.Fragment>
      )
    }
  ];

  return (
    <>
      <Row style={{ marginTop: '25px', marginBottom: '10px' }}>
        <BaseButton
          type="default"
          icon="plus"
          text="Adicionar campo"
          onClick={() => {
            if (
              advertisingVinylRef.length < 10 &&
              (advertisingVinylRef.length === 0 ||
                (advertisingVinylRef.length > 0 &&
                  advertisingVinylRef[0].reference !== ''))
            ) {
              fields.unshift({ reference: '' });
            }
          }}
        />
      </Row>
      <Row>
        <Table
          columns={columns}
          currentPage={1}
          pageSize={advertisingVinylRef.length}
          loading={false}
          rows={advertisingVinylRef}
          showHeader={true}
          emptyIcon="solution"
          emptyText={'Não existe nenhuma referencia'}
          total={advertisingVinylRef.length}
          rowKey={(record, index) => index}
        />
      </Row>
    </>
  );
};

const selector = formValueSelector('manage_events_form');

AdvertisingVinylRefsTable = connect((state) => {
  const { advertisingVinylRef } = selector(
    state,
    'hasFurniture',
    'advertisingVinylRef'
  );
  return {
    advertisingVinylRef
  };
})(AdvertisingVinylRefsTable);

export default AdvertisingVinylRefsTable;
