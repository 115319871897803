import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SectionTitle,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetPreviousEvents,
  GetNextEvents,
  GetEventsPDF,
  GetEventsCSV,
  GetEventDetailsPDF
} from '../../infra/requests/EventsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import EventsFilters from './EventsFilters';
import { getQuerySortName } from '../../infra/services/utils/QuerySortTypes';
import * as moment from 'moment';
import { Row, Icon } from 'antd';
import MultiOptionButton from '../../components/base/MultiOptionButton';

class EventsPage extends Component {
  state = {
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Nome',
        sorter: true,
        dataIndex: 'name'
      },
      {
        title: 'Inicio do evento',
        sorter: true,
        dataIndex: 'startDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Fim do evento',
        sorter: true,
        dataIndex: 'endDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Inicio da montagem',
        sorter: true,
        dataIndex: 'assemblyStartDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Fim da desmontagem',
        sorter: true,
        dataIndex: 'disassemblyEndDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        sorter: true,
        dataIndex: 'addressLocal'
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => this.onPressDownloadPDF(e, data)}>
              <Icon style={{ marginRight: 10 }} type="download" />
              {'PDF'}
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    previousSorter: '',
    previousPageSize: 30,
    previousCurrentPage: 1,
    previousRows: [],
    previousTotal: 0,
    nextSorter: '',
    nextPageSize: 30,
    nextCurrentPage: 1,
    nextRows: [],
    nextTotal: 0
  };

  componentDidMount = async () => {
    await this.updatePreviousDataTable();
    await this.updateNextDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/events/${record._id}`);
  };

  handleChangePreviousPage = (previousCurrentPage) => {
    this.setState({ previousCurrentPage }, this.updatePreviousDataTable);
  };
  handleChangeNextPage = (nextCurrentPage) => {
    this.setState({ nextCurrentPage }, this.updateNextDataTable);
  };

  handleChangePreviousRowsPerPage = (previousCurrentSize, previousPageSize) => {
    this.setState(
      { previousPageSize, currentPage: 1 },
      this.updatePreviousDataTable
    );
  };

  handleChangeNextRowsPerPage = (nextCurrentSize, nextPageSize) => {
    this.setState({ nextPageSize, currentPage: 1 }, this.updateNextDataTable);
  };

  updatePreviousDataTable = async () => {
    this.setState({ loading: true });
    try {
      const {
        previousCurrentPage,
        previousPageSize,
        filters,
        previousSorter
      } = this.state;
      const result = await GetPreviousEvents(
        previousCurrentPage,
        previousPageSize,
        filters,
        previousSorter
      );
      this.setState({
        previousRows: result.data.items,
        previousTotal: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  updateNextDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { nextCurrentPage, nextPageSize, filters, nextSorter } = this.state;
      const result = await GetNextEvents(
        nextCurrentPage,
        nextPageSize,
        filters,
        nextSorter
      );
      this.setState({
        nextRows: result.data.items,
        nextTotal: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  updateBothDataTables = async () => {
    await this.updatePreviousDataTable();
    await this.updateNextDataTable();
  };

  handlePreviousTableChange = (pagination, filters, sorter) => {
    const sortObject = JSON.stringify({
      [sorter.field]: getQuerySortName(sorter.order)
    });
    this.setState({ previousSorter: sortObject }, this.updatePreviousDataTable);
  };

  handleNextTableChange = (pagination, filters, sorter) => {
    const sortObject = JSON.stringify({
      [sorter.field]: getQuerySortName(sorter.order)
    });
    this.setState({ nextSorter: sortObject }, this.updateNextDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateBothDataTables);
  };

  onPressChoice = async (event) => {
    const today = moment().format('DD_MM_YYYY');
    const filename = 'Lista_Eventos';
    if (event.key === 'CSV') {
      const requestBlob = await GetEventsCSV();
      const url = URL.createObjectURL(requestBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}_${today}.csv`;
      a.click();
    }
    if (event.key === 'PDF') {
      const requestBlob = await GetEventsPDF();
      const url = URL.createObjectURL(requestBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}_${today}.pdf`;
      a.click();
    }
  };

  onPressDownloadPDF = async (event, data) => {
    event.stopPropagation();
    const today = moment().format('DD_MM_YYYY');
    const filename = `Evento_${data.name.trim()}_${today}`;
    const requestBlob = await GetEventDetailsPDF(data._id);
    const url = URL.createObjectURL(requestBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.pdf`;
    a.click();
  };

  render() {
    const {
      loading,
      columns,
      nextCurrentPage,
      previousCurrentPage,
      nextTotal,
      previousTotal,
      nextPageSize,
      previousPageSize,
      nextRows,
      previousRows
    } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Eventos</PageTitle>
            {'Eventos'}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/events/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={this.updateBothDataTables}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <Row type="flex" justify="end">
            <MultiOptionButton
              buttons={['CSV', 'PDF']}
              title={'Exportar'}
              icon="download"
              onPressChoice={this.onPressChoice}
            />
          </Row>
          <EventsFilters queryChange={this.handleFilterChange} />
          <SectionTitle style={{ marginTop: 15 }}>
            Próximos eventos
          </SectionTitle>

          <Table
            columns={columns}
            currentPage={nextCurrentPage}
            pageSize={nextPageSize}
            loading={loading}
            rows={nextRows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum evento'}
            total={nextTotal}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            onSortChange={this.handleNextTableChange}
            handleChangePage={this.handleChangeNextPage}
            handleChangeRowsPerPage={this.handleChangeNextRowsPerPage}
          />
          <SectionTitle style={{ marginTop: 15 }}>
            Eventos anteriores
          </SectionTitle>
          <Table
            columns={columns}
            currentPage={previousCurrentPage}
            pageSize={previousPageSize}
            loading={loading}
            rows={previousRows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum evento'}
            total={previousTotal}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            onSortChange={this.handlePreviousTableChange}
            handleChangePage={this.handleChangePreviousPage}
            handleChangeRowsPerPage={this.handleChangePreviousRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default EventsPage;
