import client from '../config/AxiosConfig';

export const GetInfo = async () => client.get('/info');

export const GetTranslations = async () =>
  client.get('/translations/platform/BO');

export const GetFile = async (id) => client.get(`/files/${id}`);

export const GetDynamicPage = async (id) => client.get(`/dynamic_pages/${id}`);
