import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import DepartmentsFilters from './DepartmentsFilters';
import { Departments } from '../../infra/services/utils/Departments';

class DepartmentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    sorter: '',
    loading: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name'
      }
    ],
    rows: Departments,
    total: 0
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  validateName = (row = { name: '' }) => {
    const { filters = { search: '' } } = this.state;
    if (row.name.toLowerCase().includes(filters.search.toLocaleLowerCase()))
      return row;
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { filters = { search: '' } } = this.state;
      let data = [];
      if (filters.search !== '' && filters.search !== undefined) {
        data = Departments.filter(this.validateName);
      } else {
        data = Departments;
      }
      this.setState({
        rows: data,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  render() {
    const { columns, rows, loading } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Departamentos</PageTitle>
            {'Departamentos'}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <DepartmentsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum registo de departamentos'}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default DepartmentsPage;
