import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PageContainer,
  HeaderContainer,
  HeaderTitle,
  PageTitle
} from '../../styles/BasicStyles';
import { getUserPermission } from '../../infra/services/utils/Permissions';
import { getAlertsTableType } from '../../infra/services/utils/AlertsTypes';
import moment from 'moment';
import {
  GetNextAlerts,
  GetNextEvents,
  GetLowMaterials
} from '../../infra/requests/DashboardRequests';
import {
  DashboardContainer,
  DashboardTitle,
  DashboardSubtitle,
  DashboardUserContainer
} from './DashboardStyles';
import Table from '../../components/generic/table/Table';
import BaseButton from '../../components/generic/buttons/BaseButton';
class DashboardPage extends Component {
  state = {
    alerts: [],
    events: [],
    materials: [],
    alertsColumns: [
      {
        title: 'Tipo',
        dataIndex: 'type',
        sorter: true,
        render: getAlertsTableType
      },
      {
        title: 'Inicio da incidência',
        dataIndex: 'startDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Fim da incidência',
        dataIndex: 'endDate',
        render: (date) =>
          date ? moment(date).format('DD-MM-YYYY') : 'Data indefinida'
      }
    ],
    eventsColumns: [
      {
        title: 'Nome',
        sorter: true,
        dataIndex: 'name'
      },
      {
        title: 'Inicio do evento',
        sorter: true,
        dataIndex: 'startDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Fim do evento',
        sorter: true,
        dataIndex: 'endDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Inicio da montagem',
        sorter: true,
        dataIndex: 'assemblyStartDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      }, 
      {
        title: 'Fim da desmontagem',
        sorter: true,
        dataIndex: 'disassemblyEndDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Local',
        sorter: true,
        dataIndex: 'addressLocal'
      }
    ],
    materialsColumns: [
      {
        title: 'Nome',
        sorter: true,
        dataIndex: 'name'
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: (data) => (data && typeof data === 'object' ? data.name : '')
      },
      {
        title: 'Subcategoria',
        dataIndex: 'subcategory'
      },
      {
        title: 'Quantidade',
        sorter: true,
        dataIndex: 'quantity'
      }
    ]
  };

  componentDidMount = async () => {
    const { user } = this.props;

    await this.getNextEvents();
    if (user.permission <= 1) {
      await this.getNextAlerts();
      await this.getLowMaterials();
    }
  };

  getNextAlerts = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetNextAlerts();
      this.setState({
        alerts: result.data,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  getNextEvents = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetNextEvents();
      this.setState({
        events: result.data,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  getLowMaterials = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetLowMaterials();
      this.setState({
        materials: result.data,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record, address) => {
    const { history } = this.props;
    history.push(`/${address}/${record._id}`);
  };

  render() {
    const { user, history } = this.props;
    const {
      loading,
      eventsColumns,
      events,
      materialsColumns,
      materials,
      alertsColumns,
      alerts
    } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle>
            <PageTitle>Dashboard</PageTitle>
            {'Dashboard'}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <DashboardContainer>
            <DashboardUserContainer>
              <DashboardTitle>{'Utilizador: '}</DashboardTitle>
              <DashboardSubtitle>{`Nome: ${user.name}`}</DashboardSubtitle>
              <DashboardSubtitle>{`Email: ${user.email}`}</DashboardSubtitle>
              <DashboardSubtitle>{`Permissões: ${getUserPermission(
                user.permission
              )}`}</DashboardSubtitle>
            </DashboardUserContainer>

            {user.permission <= 1 && (
              <>
                <DashboardTitle>{'Alertas para o próximo mês: '}</DashboardTitle>
                <Table
                  columns={alertsColumns}
                  loading={loading}
                  rows={alerts}
                  showHeader={true}
                  emptyIcon="solution"
                  emptyText={
                    'Não existe nenhum registo de alertas no próximo mês'
                  }
                  rowKey={(record) => record._id}
                  onPressRow={(record) =>
                    this.handleOnPressRow(record, 'alerts')
                  }
                />
                <div style={{ float: 'right', marginTop: '30px' }}>
                  <BaseButton
                    type="primary"
                    icon="arrow-right"
                    text="Mais alertas"
                    onClick={() => history.push('/alerts')}
                  />
                </div>
              </>
            )}    

            <DashboardTitle>{'Eventos no próximo mês: '}</DashboardTitle>
            <Table
              columns={eventsColumns}
              loading={loading}
              rows={events}
              showHeader={true}
              emptyIcon="solution"
              emptyText={'Não existe nenhum registo de eventos no próximo mês'}
              rowKey={(record) => record._id}
              onPressRow={(record) => this.handleOnPressRow(record, 'events')}
            />
            <div style={{ float: 'right', marginTop: '30px' }}>
              <BaseButton
                type="primary"
                icon="arrow-right"
                text="Mais eventos"
                onClick={() => history.push('/events')}
              />
            </div>

            {user.permission <= 1 && (
              <>
                <DashboardTitle>{'Materiais com baixo stock: '}</DashboardTitle>
                <Table
                  columns={materialsColumns}
                  loading={loading}
                  rows={materials}
                  showHeader={true}
                  emptyIcon="solution"
                  emptyText={
                    'Não existe nenhum registo de materias com baixo stock'
                  }
                  rowKey={(record) => record._id}
                  onPressRow={(record) =>
                    this.handleOnPressRow(record, 'materials')
                  }
                />
                <div style={{ float: 'right', marginTop: '30px' }}>
                  <BaseButton
                    type="primary"
                    icon="arrow-right"
                    text="Mais materiais"
                    onClick={() => history.push('/materials')}
                  />
                </div>
              </>
            )}
          </DashboardContainer>
        </PageContainer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(DashboardPage);
