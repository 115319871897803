import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import TextInput from '../../../components/generic/inputs/TextInput';
import StepSection from './components/StepSection';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import { EventStandTypes } from '../../../infra/services/utils/StandTypes';
import { EventBuildTypes } from '../../../infra/services/utils/BuildTypes';
import NumberInput from '../../../components/generic/inputs/NumberInput';
import { EventPlateTypes } from '../../../infra/services/utils/PlateTypes';
import TextAreaInput from '../../../components/generic/inputs/TextAreaInput';
import ExternalMaterialsTable from './components/ExternalMaterialsTable';
import TeamsTable from './components/TeamsTable';
import VehiclesTable from './components/VehiclesTable';
import SwitchInput from '../../../components/generic/inputs/SwitchInput';
import { EventPlatformTypes } from '../../../infra/services/utils/PlatformTypes';
import { EventPlatformMaterials } from '../../../infra/services/utils/PlatformMaterials';
import { EventElectricTypes } from '../../../infra/services/utils/ElectricTypes';
import FurnituresTable from './components/FurnituresTable';
import BlueprintsTable from './components/BlueprintsTable';
import AdvertisingVinylRefsTable from './components/AdvertisingVinylRefsTable';
import { EventDisassemblyImages } from '../../../infra/services/utils/DisassemblyImages';
import { StyledLastSection } from '../EventsStyles';
import FilesInput from '../../../components/generic/inputs/FilesInput';
import MaterialsTable from './components/MaterialsTable';

const EventDetailedSteps = ({
  currentProgress,
  setCurrentProgress,
  teams,
  hasPlatform,
  hasElectricity,
  isOtherOptionSelected,
  hasFurniture,
  hasAdvertising,
  hasBlueprints,
  handleAddFile,
  handleDeleteFile
}) => {
  return (
    <>
      <StepSection
        currentProgress={currentProgress}
        currentIndex={4}
        title="Construção"
        setCurrentProgress={setCurrentProgress}
      />
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={11}>
          <Field
            component={SelectInput}
            name="standType"
            label="Tipo de stand"
            placeholder="Escolha o tipo de stand..."
            data={EventStandTypes}
          />
        </Col>
        <Col xs={24} md={11}>
          <Field
            component={SelectInput}
            name="buildType"
            label="Tipo de contrução"
            placeholder="Escolha o tipo de contrução..."
            data={EventBuildTypes}
          />
        </Col>
      </Row>
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={7}>
          <Field
            component={NumberInput}
            name="measureWidth"
            label="Largura"
            placeholder="Escreva a largura"
          />
        </Col>
        <Col xs={24} md={7}>
          <Field
            component={NumberInput}
            name="measureHeight"
            label="Altura"
            placeholder="Escreva a altura"
          />
        </Col>
        <Col xs={24} md={7}>
          <Field
            component={NumberInput}
            name="fronts"
            label="Frentes"
            placeholder="Escreva as frentes"
          />
        </Col>
      </Row>
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={11}>
          <Field
            component={SelectInput}
            name="plateType"
            label="Tipo de placa"
            placeholder="Escolha o tipo de placa..."
            data={EventPlateTypes}
          />
        </Col>
        <Col xs={24} md={11}>
          <Field
            component={TextInput}
            name="profileColour"
            type="text"
            label="Cor de perfil"
            placeholder="Escreva a cor de perfil"
          />
        </Col>
        <Col xs={24} md={24}>
          <Field
            component={TextAreaInput}
            name="refColour"
            maxRows={2}
            minRows={2}
            type="text"
            label="Cor / Refª / Mat."
            placeholder="Escreva a cor / referência / material"
          />
        </Col>
      </Row>
      <Field
        component={TextAreaInput}
        name="remarks"
        type="text"
        label="Observações"
        minRows={8}
        maxRows={8}
        placeholder="Escreva as observações"
      />
      <StepSection
        currentProgress={currentProgress}
        currentIndex={5}
        title="Materiais"
        setCurrentProgress={setCurrentProgress}
      />
      <FieldArray
        component={MaterialsTable}
        name="materials"
        objectName="materials"
      />
      <Row style={{ marginTop: '25px' }}>
        <FieldArray
          component={ExternalMaterialsTable}
          name="externalMaterials"
          objectName="externalMaterials"
        />
      </Row>
      <StepSection
        currentProgress={currentProgress}
        currentIndex={6}
        title="Equipas"
        setCurrentProgress={setCurrentProgress}
      />
      <FieldArray component={TeamsTable} name="teams" objectName="teams" />
      <StepSection
        currentProgress={currentProgress}
        currentIndex={7}
        title="Veiculos"
        setCurrentProgress={setCurrentProgress}
      />
      <FieldArray
        component={VehiclesTable}
        name="vehicles"
        objectName="vehicles"
      />

      <StepSection
        currentProgress={currentProgress}
        currentIndex={8}
        title="Piso"
        setCurrentProgress={setCurrentProgress}
      />
      <Field component={SwitchInput} name="hasPlatform" label="Tem estrado?" />
      {hasPlatform && (
        <>
          <Row type={'flex'} justify={'space-between'}>
            <Col xs={24} md={8}>
              <Field
                component={SelectInput}
                name="platformType"
                label="Tipo de piso"
                placeholder="Escolha o tipo de piso..."
                data={EventPlatformTypes}
              />
            </Col>
            <Col xs={24} md={14}>
              <Field
                component={TextInput}
                name="platformColour"
                type="text"
                label="Cor do piso"
                placeholder="Escreva a cor do piso"
              />
            </Col>
          </Row>
          <Row type={'flex'} justify={'space-between'}>
            <Col xs={24} md={8}>
              <Field
                component={SelectInput}
                name="platformMaterial"
                label="Material do piso"
                placeholder="Escolha o material do piso..."
                data={EventPlatformMaterials}
              />
            </Col>
            {isOtherOptionSelected && (
              <Col xs={24} md={14}>
                <Field
                  component={TextInput}
                  name="platformOtherMaterial"
                  type="text"
                  label="Material"
                  placeholder="Escreva o nome do material"
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <StepSection
        currentProgress={currentProgress}
        currentIndex={9}
        title="Teto"
        setCurrentProgress={setCurrentProgress}
      />
      <Field component={SwitchInput} name="hasCeiling" label="Tem teto?" />
      <StepSection
        currentProgress={currentProgress}
        currentIndex={10}
        title="Limpeza"
        setCurrentProgress={setCurrentProgress}
      />
      <Field component={SwitchInput} name="hasCleaning" label="Tem limpeza?" />
      <StepSection
        currentProgress={currentProgress}
        currentIndex={11}
        title="Eletricidade"
        setCurrentProgress={setCurrentProgress}
      />
      <Field
        component={SwitchInput}
        name="hasElectricity"
        label="Tem eletricidade?"
      />
      {hasElectricity && (
        <>
          <Field
            component={SelectInput}
            name="electricitySystem"
            label="Sistema de eletricidade"
            placeholder="Escolha o sistema..."
            data={EventElectricTypes}
          />
          <Field
            component={TextAreaInput}
            name="electricityRemarks"
            type="text"
            label="Observações"
            minRows={3}
            maxRows={3}
            placeholder="Escreva as observações"
          />
        </>
      )}
      <StepSection
        currentProgress={currentProgress}
        currentIndex={12}
        title="Moveis"
        setCurrentProgress={setCurrentProgress}
      />
      <Field component={SwitchInput} name="hasFurniture" label="Tem móveis?" />
      {hasFurniture && (
        <FieldArray
          component={FurnituresTable}
          name="furnitures"
          objectName="furnitures"
        />
      )}
      <StepSection
        currentProgress={currentProgress}
        currentIndex={13}
        title="Plantas"
        setCurrentProgress={setCurrentProgress}
      />
      <Field
        component={SwitchInput}
        name="hasBlueprints"
        label="Tem plantas?"
      />
      {hasBlueprints && (
        <FieldArray
          component={BlueprintsTable}
          name="blueprints"
          objectName="blueprints"
        />
      )}
      <StepSection
        currentProgress={currentProgress}
        currentIndex={14}
        title="Publicidade"
        setCurrentProgress={setCurrentProgress}
      />
      <Field
        component={SwitchInput}
        name="hasAdvertising"
        label="Tem publicidade?"
      />
      {hasAdvertising && (
        <>
          <Row type={'flex'} justify={'space-between'}>
            <Col xs={24} md={11}>
              <Field
                component={SwitchInput}
                name="advertisingVinylCutting"
                label="Corte de vinyl?"
              />
            </Col>
            <Col xs={24} md={11}>
              <Field
                component={SwitchInput}
                name="advertisingDigitalPrinting"
                label="Impressão digital?"
              />
            </Col>
          </Row>
          <FieldArray
            component={AdvertisingVinylRefsTable}
            name="advertisingVinylRef"
            objectName="advertisingVinylRef"
          />
          <Field
            component={TextAreaInput}
            name="advertisingRemarks"
            type="text"
            label="Observações"
            minRows={11}
            maxRows={11}
            placeholder="Escreva as observações da publicidade"
          />
          <Field
            component={SelectInput}
            name="disassemblyImages"
            label="Ao desmontar as imagens"
            placeholder="Escolha..."
            data={EventDisassemblyImages}
          />
        </>
      )}
      <StyledLastSection>
        <StepSection
          currentProgress={currentProgress}
          currentIndex={15}
          title="Ficheiros"
          setCurrentProgress={setCurrentProgress}
        />
        <FieldArray
          component={FilesInput}
          name="files"
          handleAdd={handleAddFile}
          handleDelete={handleDeleteFile}
        />
      </StyledLastSection>
    </>
  );
};

EventDetailedSteps.propTypes = {
  setCurrentProgress: PropTypes.func.isRequired,
  currentProgress: PropTypes.number.isRequired,
  teams: PropTypes.array.isRequired,
  hasPlatform: PropTypes.bool.isRequired,
  hasElectricity: PropTypes.bool.isRequired,
  isOtherOptionSelected: PropTypes.bool.isRequired,
  hasFurniture: PropTypes.bool.isRequired,
  hasAdvertising: PropTypes.bool.isRequired,
  hasBlueprints: PropTypes.bool.isRequired,
  handleAddFile: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired
};

export default EventDetailedSteps;
