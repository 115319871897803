import React, { Component } from 'react';
import { Popconfirm, Icon, Row } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetMaterials,
  DeleteMaterial,
  GetMaterialCSV,
  GetMaterialPDF
} from '../../infra/requests/MaterialsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import MaterialsFilters from './MaterialsFilters';
import { getQuerySortName } from '../../infra/services/utils/QuerySortTypes';
import AddMaterialsModal from './AddMaterialsModal';
import MultiOptionButton from '../../components/base/MultiOptionButton';
import moment from 'moment';

class MaterialsPage extends Component {
  state = {
    material: {},
    isAddMaterialOpen: false,
    pageSize: 30,
    currentPage: 1,
    filters: '',
    sorter: '',
    loading: false,
    columns: [
      {
        title: 'Nome',
        sorter: true,
        dataIndex: 'name'
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: (data) => (data && typeof data === 'object' ? data.name : '')
      },
      {
        title: 'Subcategoria',
        dataIndex: 'subcategory'
      },
      {
        title: 'Quantidade',
        sorter: true,
        dataIndex: 'quantity'
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <div onClick={() => this.openAddStockModal(data)}>
                <Icon style={{ marginRight: 10 }} type="plus" />
                {'Adicionar stock'}
              </div>
            </TableButton>
            <TableButton onClick={(e) => this.onPressEvents(e, data)}>
              <Icon style={{ marginRight: 10 }} type="carry-out" />
              {'Eventos'}
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover o material?"
                onConfirm={() => this.removeMaterial(data)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  onPressEvents = (event, data) => {
    const { history } = this.props;
    event.stopPropagation();
    history.push(`/materials/${data._id}/events`);
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/materials/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeMaterial = async (data) => {
    try {
      await DeleteMaterial(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters, sorter } = this.state;
      const result = await GetMaterials(currentPage, pageSize, filters, sorter);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortObject = JSON.stringify({
      [sorter.field]: getQuerySortName(sorter.order)
    });
    this.setState({ sorter: sortObject }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  openAddStockModal = (data) => {
    this.setState({ material: data, isAddMaterialOpen: true });
  };

  closeModalAndUpdate = async () => {
    await this.updateDataTable();
    this.setState({ isAddMaterialOpen: false });
  };

  renderRowClass = (record, index) => {
    if (record.quantity < 0) {
      return 'table-no-stock';
    }

    if (record.quantity < record.stockAlert) {
      return 'table-low-stock';
    }

    return '';
  };

  onPressChoice = async (event) => {
    const today = moment().format('DD_MM_YYYY');
    const filename = 'Lista_Materiais';
    if (event.key === 'CSV') {
      const requestBlob = await GetMaterialCSV();
      const url = URL.createObjectURL(requestBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}_${today}.csv`;
      a.click();
    }
    if (event.key === 'PDF') {
      const requestBlob = await GetMaterialPDF();
      const url = URL.createObjectURL(requestBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}_${today}.pdf`;
      a.click();
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      material,
      isAddMaterialOpen
    } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Materiais</PageTitle>
            {'Materiais'}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/materials/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <Row type="flex" justify="end">
            <MultiOptionButton
              buttons={['CSV', 'PDF']}
              title={'Exportar'}
              icon="download"
              onPressChoice={this.onPressChoice}
            />
          </Row>
          <MaterialsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rowClassName={this.renderRowClass}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum registo de materiais'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            onSortChange={this.handleTableChange}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>

        <AddMaterialsModal
          material={material}
          open={isAddMaterialOpen}
          closeModal={() => this.setState({ isAddMaterialOpen: false })}
          closeModalAndUpdate={this.closeModalAndUpdate}
        />
      </React.Fragment>
    );
  }
}

export default MaterialsPage;
