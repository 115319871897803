export const EventStandTypes = [
  { _id: 'stand', name: 'Stand' },
  { _id: 'tent', name: 'Tenda' }
];

export const getEventStandType = (value) => {
  switch (value) {
    case 'stand':
      return 'Stand';
    case 'tent':
      return 'Tenda';
    default:
      break;
  }
};
