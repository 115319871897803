import React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import StepSection from './components/StepSection';
import DateInput from '../../../components/generic/inputs/DateInput';

const EventDatesStep = ({ currentProgress, setCurrentProgress, change }) => {
  return (
    <>
      <StepSection
        currentProgress={currentProgress}
        currentIndex={3}
        title="Datas"
        setCurrentProgress={setCurrentProgress}
      />
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={11}>
          <Field
            component={DateInput}
            name="assemblyStartDate"
            placeholder="Inicio da montagem"
            label="Inicio da montagem"
            afterChangeDate={(date) => {
              change('assemblyEndDate', date);
              change('startDate', date);
              change('endDate', date);
              change('disassemblyStartDate', date);
              change('disassemblyEndDate', date);
            }}
          />
        </Col>
        <Col xs={24} md={11}>
          <Field
            component={DateInput}
            name="assemblyEndDate"
            placeholder="Fim da montagem"
            label="Fim da montagem"
          />
        </Col>
      </Row>
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={11}>
          <Field
            component={DateInput}
            name="startDate"
            placeholder="Inicio do evento"
            label="Inicio do evento"
          />
        </Col>
        <Col xs={24} md={11}>
          <Field
            component={DateInput}
            name="endDate"
            placeholder="Fim do evento"
            label="Fim do evento"
          />
        </Col>
      </Row>
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={11}>
          <Field
            component={DateInput}
            name="disassemblyStartDate"
            placeholder="Inicio da desmontagem"
            label="Inicio da desmontagem"
          />
        </Col>
        <Col xs={24} md={11}>
          <Field
            component={DateInput}
            name="disassemblyEndDate"
            placeholder="Fim da desmontagem"
            label="Fim da desmontagem"
          />
        </Col>
      </Row>
    </>
  );
};

EventDatesStep.propTypes = {
  setCurrentProgress: PropTypes.func.isRequired,
  currentProgress: PropTypes.number.isRequired
};

export default EventDatesStep;
