export const VehiclesCategoryTypes = [
  { _id: 'truck', name: 'Camião' },
  { _id: 'tractor', name: 'Tractor' },
  { _id: 'van', name: 'Carrinha' },
  { _id: 'commercial', name: 'Comercial' }
];

export const getVehiclesCategoryTypes = (value) => {
  switch (value) {
    case 'truck':
      return 'Camião';
    case 'tractor':
      return 'Tractor';
    case 'van':
      return 'Carrinha';
    case 'commercial':
      return 'Comercial';
    default:
      break;
  }
};
