import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Modal, notification} from 'antd';
import ImageComponent from '../images/ImageComponent';
import {
  InputDiv,
  InputLabelDiv,
  ImageInputSection,
  ImageIcon,
  ImageMessage
} from './InputStyles';

class ImageInput extends Component {
  state = {
    openModal: false,
    uploaded: undefined
  };

  onCancel = () => {
    this.setState({openModal: false});
  };

  buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  handleImageDrop = (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Invalid File',
        description: 'The file is invalid or has more than 5Mb'
      });
    }
    const uploaded = this.buildImageObject(accepted[0]);
    this.setState({openModal: true, uploaded});
  };

  onCropComplete = () => {
    const {input} = this.props;
    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      const image = this.buildImageObject(blob);
      input.onChange(image);
      this.setState({openModal: false, uploaded: undefined});
    });
  };

  renderSelectedMedia = ({getRootProps, getInputProps}) => {
    const {input, meta, width, ratio} = this.props;
    const {invalid, submitFailed} = meta;
    const showError = invalid && submitFailed ? 1 : 0;

    if (input?.value?.preview) {
      return (
        <ImageInputSection error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          <ImageComponent
            url={input.value.preview}
            width={width}
            ratio={ratio}
          />
        </ImageInputSection>
      );
    }

    if (input?.value?.url) {
      return (
        <ImageInputSection error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          <ImageComponent url={input.value.url} width={width} ratio={ratio} />
        </ImageInputSection>
      );
    }

    return (
      <ImageInputSection error={showError} {...getRootProps()}>
        <input {...getInputProps()} />
        <ImageIcon error={showError} type="file-image" />
        <ImageMessage error={showError}>Upload an image</ImageMessage>
      </ImageInputSection>
    );
  };

  render() {
    const {label, ratio} = this.props;
    const {openModal, uploaded} = this.state;
    return (
      <InputDiv>
        <InputLabelDiv>{label}</InputLabelDiv>
        <Dropzone
          multiple={false}
          onDrop={this.handleImageDrop}
          accept="image/jpeg, image/png"
          maxSize={50000000}>
          {this.renderSelectedMedia}
        </Dropzone>
        <Modal
          maskClosable={false}
          closable={false}
          title="Upload Image"
          visible={openModal}
          onOk={this.onCropComplete}
          onCancel={this.onCancel}
          bodyStyle={{padding: 0}}
          width="600px">
          <Cropper
            ref="cropper"
            viewMode={2}
            autoCropArea={1}
            aspectRatio={1 / ratio}
            style={{height: 400, width: '100%'}}
            guides={true}
            src={uploaded ? uploaded.preview : ''}
          />
        </Modal>
      </InputDiv>
    );
  }
}

ImageInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default ImageInput;
