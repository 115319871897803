import React, {Component} from 'react'
import {Field, reduxForm, initialize, reset} from 'redux-form'
import {
  GetTeam,
  UpdateTeam,
  CreateTeam
} from '../../infra/requests/TeamsRequests'
import TextInput from '../../components/generic/inputs/TextInput'
import BaseButton from '../../components/generic/buttons/BaseButton'
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles'
import FormValidator from '../../infra/services/validations/FormValidator'
import UsersSectionPage from './UsersSection'
import ManageFormHeader from '../../components/base/ManageFormHeader'

const validations = FormValidator.make({
  name: 'required'
})

class ManageTeamsPage extends Component {
  state = {
    isNew: false,
    loading: true
  }

  componentDidMount = async () => {
    const {
      match: {params},
      dispatch
    } = this.props
    if (params.id) {
      const result = await GetTeam(params.id)
      dispatch(initialize('manage_teams_form', result.data))
      this.setState({loading: false})
    } else
      this.setState({
        isNew: true,
        loading: false
      })
  }

  onSubmit = async (values, createNewAfter, addUsersAfter) => {
    try {
      this.setState({loading: true})
      const {
        match: {params},
        history,
        dispatch
      } = this.props
      const {isNew} = this.state

      const request = isNew
        ? await CreateTeam(values)
        : await UpdateTeam(params.id, values)

      if (request.success) {
        this.setState({loading: false})
        if (addUsersAfter) {
          dispatch(reset('manage_teams_form'))
          return history.push(`/teams/${request.data._id}`)
        }
        if (createNewAfter) {
          dispatch(reset('manage_teams_form'))
          return history.push('/teams/add')
        } else {
          return history.push('/teams')
        }
      }
      return this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  handleOnCancel = async () => {
    const {history} = this.props
    return history.push('/teams')
  }

  renderUsersSection = () => {
    const {isNew} = this.state
    const {
      match: {params},
      handleSubmit,
      history
    } = this.props

    if (isNew) {
      return (
        <div style={{float: 'right', marginTop: '30px'}}>
          <BaseButton
            type='primary'
            icon='arrow-right'
            text='Adicionar utilizadores'
            onClick={handleSubmit((values) =>
              this.onSubmit(values, false, true)
            )}
          />
        </div>
      )
    } else {
      return <UsersSectionPage history={history} teamId={params.id} />
    }
  }

  render() {
    const {handleSubmit, pristine} = this.props
    const {isNew, loading} = this.state
    if (loading) return <SpinLoading />
    const title = isNew ? 'Adicionar' : 'Editar'
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Equipas', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) =>
                this.onSubmit(values, false)
              ),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) =>
                this.onSubmit(values, true)
              ),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label='Nome'
              placeholder='Escreva o nome da equipa...'
            />
            {this.renderUsersSection()}
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    )
  }
}

ManageTeamsPage = reduxForm({
  form: 'manage_teams_form',
  validate: validations
})(ManageTeamsPage)

export default ManageTeamsPage
