export const EventElectricTypes = [
  { _id: 'single-phase', name: 'Monofásico' },
  { _id: 'three-phase', name: 'Trifásico' }
];

export const getEventElectricTypes = (value) => {
  switch (value) {
    case 'single-phase':
      return 'Monofásico';
    case 'three-phase':
      return 'Trifásico';
    default:
      break;
  }
};
