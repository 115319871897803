import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetMaterialCategories } from '../../infra/requests/MaterialsRequests';
import SelectInput from '../../components/generic/inputs/SelectInput';

class MaterialsFilters extends Component {
  state = {
    name: '',
    subcategory: '',
    category: '',
    categories: []
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  componentDidMount = async () => {
    const { data } = await GetMaterialCategories();
    this.setState({ categories: data || [] });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (key !== 'categories' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { name, category, subcategory, categories } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8} xl={6}>
            <TextInput
              input={{
                value: name,
                onChange: (event) =>
                  this.onInputChange('name', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label="Nome"
              placeholder="Procurar por nome"
            />
          </Col>
          <Col xs={24} md={6} xl={5}>
            <SelectInput
              label="Categoria"
              allowClear
              placeholder="Escolha a categoria"
              data={categories}
              input={{
                value: category,
                onChange: (value) => this.onInputChange('category', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={6} xl={5}>
            <TextInput
              input={{
                value: subcategory,
                onChange: (event) =>
                  this.onInputChange('subcategory', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label="Subcategoria"
              placeholder="Procurar por subcategoria"
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default MaterialsFilters;
