import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { BaseForm, SectionTitle } from '../../../styles/BasicStyles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import TextInput from '../../../components/generic/inputs/TextInput';
import DateInput from '../../../components/generic/inputs/DateInput';
import { CreateContributorUnavailability } from '../../../infra/requests/ContributorsRequests';

const validations = FormValidator.make({
  startDate: 'required',
  endDate: 'required',
  type: 'required',
  otherReason: 'required'
});

let AddUnavailabilitiesModal = ({
  selectedDate,
  contributor,
  open,
  handleSubmit,
  closeModal,
  closeModalAndUpdate,
  reset,
  change,
  isOtherOptionSelected
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (fields) => {
    setLoading(true);
    try {
      if (fields.type === 'Outra') {
        fields.type = fields.otherReason;
      }
      const request = await CreateContributorUnavailability(
        contributor._id,
        fields
      );
      if (request.success) {
        reset();
        closeModalAndUpdate();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleOnCancel = () => {
    reset();
    closeModal();
  };

  return (
    <Modal
      visible={open}
      title="Adicionar indisponibilidade"
      maskClosable={false}
      onCancel={handleOnCancel}
      footer={[
        <Button key="back" onClick={handleOnCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Gravar
        </Button>
      ]}
    >
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>Dados do colaborador</SectionTitle>
        <Row>
          <Col xs={24}>Código: {contributor.code}</Col>
        </Row>
        <Row>
          <Col xs={24}>Nome: {contributor.name}</Col>
        </Row>
        <Row>
          <Col xs={24}>Contacto: {contributor.phone}</Col>
        </Row>
        <Row
          style={{ marginTop: '20px' }}
          type={'flex'}
          justify={'space-between'}
        >
          <Col xs={24} md={11}>
            <Field
              component={DateInput}
              name="startDate"
              placeholder="Início da indisponibilidade"
              label="Início da indisponibilidade"
              afterChangeDate={(date) => {
                change('endDate', date);
              }}
            />
          </Col>
          <Col xs={24} md={11}>
            <Field
              component={DateInput}
              name="endDate"
              placeholder="Fim da indisponibilidade"
              label="Fim da indisponibilidade"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Row type={'flex'} justify={'space-between'}>
            <Col xs={24} md={8}>
              <Field
                component={SelectInput}
                name="type"
                label="Razão"
                placeholder="Escolha a razão da indisponibilidade..."
                data={[
                  { _id: 'Evento', name: 'Evento' },
                  { _id: 'Férias', name: 'Férias' },
                  { _id: 'Falta injustificada', name: 'Falta injustificada' },
                  { _id: 'Doença', name: 'Doença' },
                  { _id: 'Outra', name: 'Outra' }
                ]}
              />
            </Col>
            {isOtherOptionSelected && (
              <Col xs={24} md={14}>
                <Field
                  component={TextInput}
                  name="otherReason"
                  type="text"
                  label="Outra"
                  placeholder="Escreva a razão"
                />
              </Col>
            )}
          </Row>
        </Row>
      </BaseForm>
    </Modal>
  );
};

AddUnavailabilitiesModal = reduxForm({
  form: 'add_unavailabilities_form',
  validate: validations,
  enableReinitialize: true
})(AddUnavailabilitiesModal);

const selector = formValueSelector('add_unavailabilities_form');

const mapStateToProps = (state) => {
  const isOtherOptionSelected = selector(state, 'type') === 'Outra';
  return {
    isOtherOptionSelected
  };
};

AddUnavailabilitiesModal = connect(mapStateToProps)(AddUnavailabilitiesModal);

export default AddUnavailabilitiesModal;
