export const getQuerySortName = (value) => {
  switch (value) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      break;
  }
};
