import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize, reset } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetUser,
  UpdateUser,
  CreateUser
} from '../../infra/requests/UsersRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { Permissions } from '../../infra/services/utils/Permissions';
import ManageFormHeader from '../../components/base/ManageFormHeader';

const validations = FormValidator.make({
  name: 'required',
  email: 'required',
  permission: 'required'
});

class ManageUsersPage extends Component {
  state = {
    isNew: false,
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    if (params.id) {
      const result = await GetUser(params.id);
      dispatch(
        initialize('manage_users_form', {
          ...result.data
        })
      );

      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch
      } = this.props;
      const { isNew } = this.state;

      const request = isNew
        ? await CreateUser(values)
        : await UpdateUser(params.id, values);

      if (request.success) {
        this.setState({ loading: false });

        if (createNewAfter) {
          dispatch(reset('manage_users_form'));
          return history.push('/users/add');
        } else {
          return history.push('/users');
        }
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/users');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Utilizadores', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Nome"
              placeholder="Primeiro e último nome"
            />
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="Email"
              placeholder="Email do utilizador"
            />
            <Field
              component={SelectInput}
              name="permission"
              data={Permissions}
              label="Permissão"
              placeholder="Escolha a permissão..."
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageUsersPage = reduxForm({
  form: 'manage_users_form',
  validate: validations
})(ManageUsersPage);

const mapStateToProps = (state) => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps)(ManageUsersPage));
