export const EventPlatformTypes = [
  { _id: 'reinforced', name: 'Reforçado' },
  { _id: 'normal', name: 'Normal' },
  { _id: 'technician', name: 'Técnico' }
];

export const getEventPlatformTypes = (value) => {
  switch (value) {
    case 'reinforced':
      return 'Reforçado';
    case 'normal':
      return 'Normal';
    case 'technician':
      return 'Técnico';
    default:
      break;
  }
};
