import React, { Component } from 'react';
import LogoHeader from '../../../assets/icons/logoHeader.png';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Icon } from 'antd';
import MenuItems from './MenuItems';
import { DeleteUser } from '../../../redux/User/user.actions';
import { withRouter } from 'react-router-dom';
import {
  StyledMenuItem,
  StyledLogo,
  StyledNavLink,
  StyledSider,
  StyledContent,
  TransitionLayout,
  MenuSVG,
  StyledSubMenu,
  StyledMenu,
  StyledLogoContainer
} from './LayoutStyles';
import HeaderComponent from './HeaderComponent';
import ComercialMenu from './ComercialMenu';

class ResponsiveSider extends Component {
  state = {
    collapsed: false
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  handleSider = () => this.setState({ collapsed: !this.state.collapsed });
  handleSiderClose = () => this.setState({ collapsed: true });

  logOut = () => {
    const { DeleteUser, history } = this.props;
    localStorage.clear();
    DeleteUser();
    history.push('/login');
  };

  renderMenuList = (item, index) => {
    const { user } = this.props;
    if (!user || user.role > item.permission_type) return null;
    switch (item.type) {
      case 'menu':
        return (
          <StyledMenuItem key={index}>
            <StyledNavLink
              to={item.to}
              exact={item.exact}
              activeClassName="activated"
            >
              {item.external_icon ? (
                <MenuSVG className="anticon" size={item.size}>
                  <ReactSVG path={item.icon} />
                </MenuSVG>
              ) : (
                <Icon
                  style={{ fontSize: item.size ? item.size : '20px' }}
                  type={item.icon}
                />
              )}
              <span>{item.name}</span>
            </StyledNavLink>
          </StyledMenuItem>
        );
      case 'submenu':
        return (
          <StyledSubMenu
            key={index}
            title={
              <>
                {item.external_icon ? (
                  <MenuSVG className="anticon" size={item.size}>
                    <ReactSVG path={item.icon} />
                  </MenuSVG>
                ) : (
                  <Icon
                    style={{ fontSize: item.size ? item.size : '20px' }}
                    type={item.icon}
                  />
                )}
                <span>{item.name}</span>
              </>
            }
          >
            {item.to.map(this.renderMenuList)}
          </StyledSubMenu>
        );
      default:
        return [];
    }
  };

  render() {
    const { collapsed } = this.state;
    const { user } = this.props;

    return (
      <Layout>
        <StyledSider
          collapsible
          collapsed={collapsed}
          onCollapse={this.handleSider}
        >
          <StyledLogoContainer>
            <StyledLogo src={LogoHeader} onClick={this.handleSider} />
          </StyledLogoContainer>
          <StyledMenu mode="inline">
            {user.permission === 1 && MenuItems.map(this.renderMenuList)}
            {user.permission === 2 && ComercialMenu.map(this.renderMenuList)}
            <StyledMenuItem key={10} onClick={this.logOut}>
              <Icon style={{ fontSize: '20px' }} type={'logout'} />
              <span>{'Logout'}</span>
            </StyledMenuItem>
          </StyledMenu>
        </StyledSider>
        <TransitionLayout collapsed={collapsed}>
          <HeaderComponent />
          <StyledContent>{this.props.children}</StyledContent>
        </TransitionLayout>
      </Layout>
    );
  }
}

ResponsiveSider.propTypes = {};

const mapStateToProps = (state) => ({ user: state.user });

const mapActionToProps = (dispatch) =>
  bindActionCreators({ DeleteUser }, dispatch);

ResponsiveSider = connect(mapStateToProps, mapActionToProps)(ResponsiveSider);

export default withRouter(ResponsiveSider);
