import React from 'react';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import configureStore, { history } from './redux/ConfigStore';
import startThunk from './redux/thunks/StartThunk';
import client from './infra/config/AxiosConfig';
import Theme from './styles/Theme';
import App from './App';
// set the moment locale to pt
import 'moment/locale/pt';

const store = configureStore();
client.setupInterceptors(store);
store.dispatch(startThunk());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizeProvider store={store}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </LocalizeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
