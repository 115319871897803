import React from 'react';
import { Element } from 'react-scroll';
import { SectionTitle } from '../../../../styles/BasicStyles';

const StepSection = ({
  currentProgress,
  currentIndex,
  title,
  setCurrentProgress
}) => {
  return (
    <Element key={`anchor-${currentIndex}`} name={`anchor-${currentIndex}`}>
      <SectionTitle
        ref={(ref) => {
          if (!ref) return;
          const { top } = ref.getBoundingClientRect();
          if (top < 173 && currentProgress < currentIndex)
            setCurrentProgress(currentIndex);

          if (top > 250 && currentProgress >= currentIndex)
            setCurrentProgress(currentIndex - 1);
        }}
        style={{ marginTop: '25px' }}
      >
        {title}
      </SectionTitle>
    </Element>
  );
};

export default StepSection;
