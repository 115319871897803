import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, change, formValueSelector } from 'redux-form';
import { Popconfirm, Icon, Row } from 'antd';
import Table from '../../../../components/generic/table/Table';
import { TableButton } from '../../../../styles/BasicStyles';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import { GetAllMaterials } from '../../../../infra/requests/MaterialsRequests';
import NumberInput from '../../../../components/generic/inputs/NumberInput';
import moment from 'moment';

let MaterialsTable = ({
  fields,
  objectName,
  dispatch,
  materials,
  startDate,
  endDate
}) => {
  const [allMaterials, setAllMaterials] = useState([]);
  const [hasFetchMaterials, setHasFetchMaterials] = useState(false);

  useEffect(() => {
    async function fetchAllMaterials() {
      const materialsRequest = await GetAllMaterials();
      setAllMaterials(materialsRequest.data);
      setHasFetchMaterials(true);
    }
    if (!hasFetchMaterials) {
      fetchAllMaterials();
    }
  }, [hasFetchMaterials]);

  const execAfterChangeMaterial = (index, value, item) => {
    const selected = allMaterials.find((material) => material._id === value);
    dispatch(
      change('manage_events_form', `materials[${index}]`, {
        material: {
          _id: selected._id,
          name: selected.name,
          consumable: selected.consumable
        }
      })
    );
  };

  const columns = [
    {
      title: 'Material',
      dataIndex: 'materials',
      width: '50%',
      render: (value, item, index) => {
        const availableItems =
          item.material._id !== ''
            ? [
                item.material,
                ...allMaterials.filter(
                  (material) =>
                    !materials.some(
                      (rowMaterial) => rowMaterial.material._id === material._id
                    )
                )
              ]
            : allMaterials.filter(
                (material) =>
                  !materials.some(
                    (rowMaterial) => rowMaterial.material._id === material._id
                  )
              );
        return (
          <FormSection name={`${objectName}[${index}].material`}>
            <Field
              component={SelectInput}
              name={'_id'}
              execAfterChange={(val) =>
                execAfterChangeMaterial(index, val, item)
              }
              disabled={isTableDisabled()}
              placeholder="Escolha o material..."
              data={availableItems}
            />
          </FormSection>
        );
      }
    },
    {
      title: 'É um consumível?',
      dataIndex: 'materials.material.consumable',
      render: (value, item, index) =>
        item.material.consumable ? 'Sim' : 'Não'
    },
    {
      title: 'Quantidade usada',
      dataIndex: 'quantity',
      width: '20%',
      render: (value, item, index) => (
        <Field
          component={NumberInput}
          disabled={isTableDisabled()}
          name={`${objectName}[${index}].quantity`}
        />
      )
    },
    {
      title: 'Operações',
      render: (value, item, index) => {
        if (isTableDisabled()) return [];
        return (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover a material?"
                onConfirm={() => fields.remove(index)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        );
      }
    }
  ];

  const isTableDisabled = () => {
    const today = moment().startOf('day');
    if (
      moment(startDate).startOf('day').isSameOrBefore(today) &&
      moment(endDate).startOf('day').isSameOrAfter(today)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Row style={{ marginTop: '25px', marginBottom: '10px' }}>
        <BaseButton
          type="default"
          icon="plus"
          text="Adicionar campo"
          disabled={isTableDisabled()}
          onClick={() => {
            if (
              materials.length === 0 ||
              (materials.length > 0 && materials[0].material._id !== '')
            ) {
              return fields.unshift({
                material: { _id: '' },
                quantity: ''
              });
            }
          }}
        />
      </Row>
      <Row>
        <Table
          columns={columns}
          currentPage={1}
          pageSize={materials.length}
          loading={false}
          rows={materials}
          showHeader={true}
          emptyIcon="solution"
          emptyText={'Não existe nenhum material'}
          total={materials.length}
          rowKey={(record) => record.material._id}
        />
      </Row>
    </>
  );
};

const selector = formValueSelector('manage_events_form');

MaterialsTable = connect((state) => {
  const { materials, startDate, endDate } = selector(
    state,
    'hasFurniture',
    'materials',
    'startDate',
    'endDate'
  );
  return {
    materials,
    startDate,
    endDate
  };
})(MaterialsTable);

export default MaterialsTable;
