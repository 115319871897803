import { GetInfo } from '../../infra/requests/BaseRequests';
import { SaveInfo } from '../Info/info.actions';
import { SaveUser } from '../User/user.actions';

export default function start() {
  return async (dispatch) => {
    try {
      const info = await GetInfo();
      dispatch(SaveUser(info.data.user));
      dispatch(SaveInfo(info?.data));
    } catch (e) {
      console.error(e);
    }
  };
}
