export const PrimaryColor = '#ef3c47';
export const SecondaryColor = '#FCC9CC';
export const ThirdColor = '#000000a6';
export const PrimaryCTA = '#CACACA';
export const SecondaryCTA = '#A5A5A5';
export const AlertColor = '#ff0000';
export const InputBorderColor = '#979797';
export const PrimaryBorderColor = '#F3F3F3';
export const SecondaryBorderColor = '#ef3c47';
export const MenuBorderColor = '#e8e8e8';
export const PrimaryBackgroundColor = '#ffffff';
export const SecondaryBackgroundColor = '#8c8c8c';
export const ThirdBackgroundColor = '#cccccc';

// contributors colors
export const SeniorColor = '#fff';
export const IntermediumColor = '#FDEDEC';
export const JuniorColor = '#D98880';
export const RecentColor = '#E74C3C';
