import React from 'react';
import { withRouter } from 'react-router-dom';
import { StyledHeader, HeaderSectionTitle } from './LayoutStyles';

const HeaderComponent = ({ history, location }) => {
  return (
    <StyledHeader>
      <HeaderSectionTitle></HeaderSectionTitle>
    </StyledHeader>
  );
};

export default withRouter(HeaderComponent);
