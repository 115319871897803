import React, { Component } from 'react';
import { Popconfirm, Icon, Row } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetVehicles,
  DeleteVehicle,
  GetVehiclesCSV,
  GetVehiclesPDF
} from '../../infra/requests/VehiclesRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import VehiclesFilters from './VehiclesFilters';
import { getVehiclesCategoryTypes } from '../../infra/services/utils/VehiclesCategoryTypes';
import MultiOptionButton from '../../components/base/MultiOptionButton';
import moment from 'moment';

class VehiclesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Matricula',
        dataIndex: 'registration'
      },
      {
        title: 'Marca',
        dataIndex: 'brand'
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: getVehiclesCategoryTypes
      },
      {
        title: 'Colaborador',
        dataIndex: 'contributor',
        render: (data) => (data && typeof data === 'object' ? data.name : '')
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => this.onPressEvents(e, data)}>
              <Icon style={{ marginRight: 10 }} type="carry-out" />
              {'Eventos'}
            </TableButton>

            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover o veiculo?"
                onConfirm={() => this.removeVehicle(data)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  onPressEvents = (event, data) => {
    const { history } = this.props;
    event.stopPropagation();
    history.push(`/vehicles/${data._id}/events`);
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/vehicles/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeVehicle = async (data) => {
    try {
      await DeleteVehicle(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters, sorter } = this.state;
      const result = await GetVehicles(currentPage, pageSize, filters, sorter);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  onPressChoice = async (event) => {
    const today = moment().format('DD_MM_YYYY');
    const filename = 'Lista_Veiculos';
    if (event.key === 'CSV') {
      const requestBlob = await GetVehiclesCSV();
      const url = URL.createObjectURL(requestBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}_${today}.csv`;
      a.click();
    }
    if (event.key === 'PDF') {
      const requestBlob = await GetVehiclesPDF();
      const url = URL.createObjectURL(requestBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}_${today}.pdf`;
      a.click();
    }
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Veiculos</PageTitle>
            {'Veiculos'}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/vehicles/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <Row type="flex" justify="end">
            <MultiOptionButton
              buttons={['CSV', 'PDF']}
              title={'Exportar'}
              icon="download"
              onPressChoice={this.onPressChoice}
            />
          </Row>

          <VehiclesFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum registo de veiculos'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default VehiclesPage;
