import React from 'react';
import { Field } from 'redux-form';
import { Element } from 'react-scroll';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { SectionTitle } from '../../../styles/BasicStyles';
import TextInput from '../../../components/generic/inputs/TextInput';
import StepSection from './components/StepSection';
import TextAreaInput from '../../../components/generic/inputs/TextAreaInput';

const EventInformationSteps = ({ currentProgress, setCurrentProgress }) => {
  return (
    <>
      <Element key={'anchor-0'} name={'anchor-0'}>
        <SectionTitle style={{ marginTop: 5 }}>Evento</SectionTitle>
      </Element>
      <Field
        component={TextInput}
        name="name"
        type="text"
        label="Nome"
        placeholder="Escreva o nome do evento"
      />
      <Field
        component={TextInput}
        name="company"
        type="text"
        label="Empresa"
        placeholder="Escreva o nome da empresa"
      />
      <Field
        component={TextInput}
        name="companyNIF"
        type="text"
        label="NIF da empresa"
        placeholder="Escreva o número de identificação fiscal"
      />
      <Field
        component={TextInput}
        name="salesman"
        type="text"
        label="Vendedor"
        placeholder="Escreva o nome do vendedor"
      />
      <StepSection
        currentProgress={currentProgress}
        currentIndex={1}
        title="Contacto"
        setCurrentProgress={setCurrentProgress}
      />
      <Field
        component={TextInput}
        name="contactName"
        type="text"
        label="Nome"
        placeholder="Escreva o nome do contacto"
      />
      <Field
        component={TextInput}
        name="contactEmail"
        type="text"
        label="Email"
        placeholder="Escreva o email do contacto"
      />
      <Field
        component={TextInput}
        name="contactPhone"
        type="text"
        label="Telemóvel"
        placeholder="Escreva o contacto"
      />
      <StepSection
        currentProgress={currentProgress}
        currentIndex={2}
        title="Morada"
        setCurrentProgress={setCurrentProgress}
      />
      <Field
        component={TextInput}
        name="addressLocal"
        type="text"
        label="Local"
        placeholder="Escreva o local"
      />
      <Row type={'flex'} justify={'space-between'}>
        <Col xs={24} md={7}>
          <Field
            component={TextInput}
            name="addressHall"
            type="text"
            label="Pavilhão"
            placeholder="Escreva o pavilhão"
          />
        </Col>
        <Col xs={24} md={7}>
          <Field
            component={TextInput}
            name="addressWard"
            type="text"
            label="Ala"
            placeholder="Escreva a ala"
          />
        </Col>
        <Col xs={24} md={7}>
          <Field
            component={TextInput}
            name="addressNumber"
            type="text"
            label="Número"
            placeholder="Escreva o número"
          />
        </Col>
      </Row>
      <Field
        component={TextAreaInput}
        name="addressObservations"
        maxRows={4}
        minRows={2}
        type="text"
        label="Observações"
        placeholder="Detalhes sobre o acesso"
      />
    </>
  );
};

EventInformationSteps.propTypes = {
  setCurrentProgress: PropTypes.func.isRequired,
  currentProgress: PropTypes.number.isRequired
};

export default EventInformationSteps;
