import client from '../config/AxiosConfig';

export const GetAllMaterials = async () => await client.get('/materials');

export const GetMaterial = async (id) => await client.get(`/materials/${id}`);

export const GetMaterials = async (currentPage, limit, filters, sorter) =>
  await client.get(
    `/materials/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const UpdateMaterial = async (id, data) =>
  await client.put(`/materials/${id}`, data);

export const CreateMaterial = async (data) =>
  await client.post('/materials', data);

export const DeleteMaterial = async (id) =>
  await client.delete(`/materials/${id}`);

export const AddMaterialStock = async (id, data) =>
  await client.post(`/materials/${id}/addStock`, data);

export const GetMaterialCategories = async () =>
  await client.get('/materials-categories');

export const CreateMaterialCategories = async (data) =>
  await client.post('/materials-categories', data);

export const RemoveMaterialCategories = async (id) =>
  await client.delete(`/materials-categories/${id}`);

export const GetMaterialEvents = async (
  materialId,
  currentPage,
  limit,
  filters,
  sorter
) =>
  await client.get(
    `/materials/${materialId}/events/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const GetMaterialPDF = async () =>
  await client.get('/materials/download-pdf', {
    responseType: 'blob'
  });
export const GetMaterialCSV = async () =>
  await client.get('/materials/download-csv', { responseType: 'blob' });
