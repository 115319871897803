import React from 'react';
import { StyledSteps, StyledStep, StyledContainer } from '../../EventsStyles';
import { EventCategories } from '../../../../infra/services/utils/EventsCategories';

const StepsSlider = ({ currentProgress, selectAndScrollTo }) => {
  return (
    <StyledContainer>
      <StyledSteps progressDot current={currentProgress} direction="vertical">
        {EventCategories.map((title, index) => (
          <StyledStep
            key={index}
            onClick={() => selectAndScrollTo(index)}
            title={title}
          />
        ))}
      </StyledSteps>
    </StyledContainer>
  );
};

export default StepsSlider;
