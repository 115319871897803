export default [
  {
    type: 'menu',
    name: 'Dashboard',
    to: '/',
    icon: 'read',
    exact: true
  },
  {
    type: 'menu',
    name: 'Utilizadores',
    to: '/users',
    icon: 'lock',
    exact: true
  },
  {
    type: 'menu',
    name: 'Alertas',
    to: '/alerts',
    icon: 'alert',
    exact: true
  },
  {
    type: 'submenu',
    name: 'Pessoal',
    icon: 'solution',
    to: [
      {
        type: 'menu',
        name: 'Colaboradores',
        to: '/contributors',
        icon: 'user',
        exact: true
      },
      {
        type: 'menu',
        name: 'Equipas',
        to: '/teams',
        icon: 'team',
        exact: true
      },
      {
        type: 'menu',
        name: 'Departamentos',
        to: '/departments',
        icon: 'apartment',
        exact: true
      }
    ]
  },
  {
    type: 'menu', 
    name: 'Veículos',   
    to: '/vehicles',  
    icon: 'car',
    exact: true  
  },
  {
    type: 'menu',
    name: 'Materiais',  
    to: '/materials', 
    icon: 'gold',
    exact: true
  },
  {
    type: 'menu',
    name: 'Eventos',
    to: '/events',
    icon: 'carry-out',
    exact: true
  }
];
