export const EventPlatformMaterials = [
  { _id: 'carpet', name: 'Alcatifa' },
  { _id: 'floor', name: 'Soalho' },
  { _id: 'linoleum', name: 'Linóleo' },
  { _id: 'other', name: 'Outro' }
];

export const getEventPlatformMaterials = (value) => {
  switch (value) {
    case 'carpet':
      return 'Alcatifa';
    case 'floor':
      return 'Soalho';
    case 'linoleum':
      return 'Linóleo';
    case 'other':
      return 'Outro';
    default:
      break;
  }
};
