import client from '../config/AxiosConfig';
import { buildUpdateEventData } from '../services/utils/build_forms/BuildUpdateEventData';

export const GetEvent = async (id) => await client.get(`/events/${id}`);

export const GetPreviousEvents = async (currentPage, limit, filters, sorter) =>
  await client.get(
    `/events/previous/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const GetNextEvents = async (currentPage, limit, filters, sorter) =>
  await client.get(
    `/events/next/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const UpdateEvent = async (id, data) =>
  await client.put(`/events/${id}`, buildUpdateEventData(data));

export const CreateEvent = async (data) => await client.post('/events', data);

export const AddFile = async (id, file) =>
  await client.put(`/events/${id}/file`, file);

export const RemoveFile = async (id, fileId) =>
  await client.delete(`/events/${id}/file/${fileId}`);

export const GetEventsPDF = async () =>
  await client.get('/events/download-pdf', {
    responseType: 'blob'
  });

export const GetEventsCSV = async () =>
  await client.get('/events/download-csv', { responseType: 'blob' });

export const GetEventDetailsPDF = async (id) =>
  await client.get(`/events/${id}/download-pdf`, { responseType: 'blob' });
