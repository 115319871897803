export const QuantityTypes = [
  { _id: 'kg', name: 'Kilogramas' },
  { _id: 'm', name: 'Metros' },
  { _id: 'l', name: 'Litros' },
  { _id: 'unit', name: 'Unidades' }
];

export const getQuantityType = (value) => {
  switch (value) {
    case 'kg':
      return 'Kilogramas';
    case 'm':
      return 'Metros';
    case 'l':
      return 'Litros';
    case 'unit':
      return 'Unidades';
    default:
      break;
  }
};
