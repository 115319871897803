import React, {Component} from 'react'
import {Popconfirm, Icon, Row, Col} from 'antd'
import Table from '../../components/generic/table/Table'
import {TableButton} from '../../styles/BasicStyles'
import {
  DeleteTeamContributor,
  GetTeam,
  CreateTeamContributors
} from '../../infra/requests/TeamsRequests'
import FormValidator from '../../infra/services/validations/FormValidator'
import {GetContributorsWithoutTeams} from '../../infra/requests/ContributorsRequests'
import {getDepartment} from '../../infra/services/utils/Departments'
import {Field, reduxForm, reset} from 'redux-form'
import SelectInput from '../../components/generic/inputs/SelectInput'
import BaseButton from '../../components/generic/buttons/BaseButton'

const validations = FormValidator.make({
  contributor: 'required'
})

class UsersSectionPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teamId: props.teamId,
      loading: true,
      contributors: [],
      columns: [
        {
          title: 'Código',
          dataIndex: 'contributor',
          render: (contributor) => contributor.code,
          key: 'code'
        },
        {
          title: 'Nome',
          dataIndex: 'contributor',
          render: (contributor) => contributor.name,
          key: 'code1'
        },
        {
          title: 'Contacto',
          dataIndex: 'contributor',
          render: (contributor) => contributor.phone,
          key: 'code2'
        },
        {
          title: 'Departamento',
          dataIndex: 'contributor',
          render: (contributor) => getDepartment(contributor.department),
          key: 'code3'
        },
        {
          title: '',
          render: (data) => (
            <React.Fragment>
              <TableButton error onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='Tem a certeza que quer remover o colaborador da equipa?'
                  onConfirm={() => this.removeContributorFromTeam(data)}
                >
                  <Icon style={{marginRight: 10}} type='close' />
                  {'Remover'}
                </Popconfirm>
              </TableButton>
            </React.Fragment>
          )
        }
      ],
      rows: [],
      total: 0
    }
  }

  componentDidMount = async () => {
    await this.updateContributors()
    await this.updateDataTable()
  }

  removeContributorFromTeam = async (data) => {
    try {
      await DeleteTeamContributor(data._id)
      await this.updateDataTable()
      await this.updateContributors()
    } catch (e) {
      console.error(e)
    }
  }

  updateDataTable = async () => {
    this.setState({loading: true})
    try {
      const {teamId} = this.state
      if (teamId) {
        const result = await GetTeam(teamId)
        this.setState({
          rows: result.data.contributors,
          loading: false
        })
      }
    } catch (e) {
      this.setState({loading: false})
      console.error(e)
    }
  }

  updateContributors = async () => {
    const {data} = await GetContributorsWithoutTeams()
    this.setState({contributors: data})
  }

  onSubmit = async (values) => {
    try {
      const {dispatch} = this.props
      const {teamId} = this.state
      this.setState({loading: true})
      await CreateTeamContributors({...values, team: teamId})
      await this.updateDataTable()
      await this.updateContributors()
      dispatch(reset('manage_contributors_teams_form'))
      return this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }

  handleOnPressRow = (record) => {
    const {history} = this.props
    history.push(`/contributors/${record.contributor._id}`)
  }

  render() {
    const {handleSubmit} = this.props
    const {columns, rows, total, loading, contributors} = this.state
    return (
      <React.Fragment>
        <Row type='flex' align='bottom' style={{marginBottom: '10px'}}>
          <Col xs={24} md={19}>
            <Field
              component={SelectInput}
              name='contributor'
              mode={'multiple'}
              data={contributors}
              label='Colaboradores'
              placeholder='Escolha os colaboradores...'
            />
          </Col>
          <Col xs={24} md={5}>
            <BaseButton
              icon='plus'
              text='Adicionar'
              style={{float: 'right'}}
              onClick={handleSubmit(this.onSubmit)}
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='solution'
          emptyText={'Ainda não existe nenhum colaborador associado'}
          total={total}
          rowKey={'_id'}
          onPressRow={this.handleOnPressRow}
        />
      </React.Fragment>
    )
  }
}

export default reduxForm({
  form: 'manage_contributors_teams_form',
  validate: validations
})(UsersSectionPage)
