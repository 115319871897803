import DashboardPage from '../../pages/dashboard/DashboardPage';
import UsersPage from '../../pages/users/UsersPage';
import ManageUsersPage from '../../pages/users/ManageUsersPage';
import MaterialsPage from '../../pages/materials/MaterialsPage';
import ManageMaterialsPage from '../../pages/materials/ManageMaterialsPage';
import VehiclesPage from '../../pages/vehicles/VehiclesPage';
import ManageVehiclesPage from '../../pages/vehicles/ManageVehiclesPage';
import ContributorsPage from '../../pages/contributors/ContributorsPage';
import ContributorsCalendarPage from '../../pages/contributors/ContributorsCalendarPage';
import ManageContributorsPage from '../../pages/contributors/ManageContributorsPage';
import DepartmentsPage from '../../pages/departments/DepartmentsPage';
import TeamsPage from '../../pages/teams/TeamsPage';
import ManageTeamsPage from '../../pages/teams/ManageTeamsPage';
import EventsPage from '../../pages/events/EventsPage';
import ManageEventsPage from '../../pages/events/ManageEventsPage';
import AlertsPage from '../../pages/alerts/AlertsPage';
import ContributorsEventPage from '../../pages/contributors/ContributorsEventPage';
import MaterialsEventPage from '../../pages/materials/MaterialsEventPage';
import VehiclesEventPage from '../../pages/vehicles/VehiclesEventPage';

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/alerts',
    component: AlertsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/users/add',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/users/:id',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/materials',
    component: MaterialsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/materials/add',
    component: ManageMaterialsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/materials/:id',
    component: ManageMaterialsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/materials/:id/events',
    component: MaterialsEventPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/vehicles',
    component: VehiclesPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/vehicles/add',
    component: ManageVehiclesPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/vehicles/:id',
    component: ManageVehiclesPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/vehicles/:id/events',
    component: VehiclesEventPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/contributors',
    component: ContributorsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/contributors/add',
    component: ManageContributorsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/contributors/:id',
    component: ManageContributorsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/contributors/:id/calendar',
    component: ContributorsCalendarPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/contributors/:id/events',
    component: ContributorsEventPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/departments',
    component: DepartmentsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/teams',
    component: TeamsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/teams/add',
    component: ManageTeamsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/teams/:id',
    component: ManageTeamsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/events',
    component: EventsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/events/add',
    component: ManageEventsPage,
    isAuthenticated: true,
    role: 1
  },
  {
    path: '/events/:id',
    component: ManageEventsPage,
    isAuthenticated: true,
    role: 1
  }
];
