export const EventDisassemblyImages = [
  { _id: 'customer-delivery-fair', name: 'Entregar ao cliente na feira' },
  {
    _id: 'customer-delivery-company',
    name: 'Trazer para o cliente vir buscar à spormex'
  },
  { _id: 'save-next-fair', name: 'Trazer e guardar para a próxima feira' },
  {
    _id: 'trash-or-stock',
    name: 'Trazer e deitar fora ou reutilizar o material'
  }
];

export const getEventDisassemblyImages = (value) => {
  switch (value) {
    case 'customer-delivery-fair':
      return 'Entregar ao cliente na Feira';
    case 'customer-delivery-company':
      return 'Trazer para o cliente vir buscar à spormex';
    case 'save-next-fair':
      return 'Trazer e guardar para a próxima feira';
    case 'trash-or-stock':
      return 'Trazer e deitar fora ou reutilizar o material';
    default:
      break;
  }
};
