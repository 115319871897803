import client from '../config/AxiosConfig';

export const GetContributor = async (id) =>
  await client.get(`/contributors/${id}`);

export const GetAllContributors = async () => await client.get('/contributors');

export const GetContributors = async (currentPage, limit, filters, sorter) =>
  await client.get(
    `/contributors/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const UpdateContributor = async (id, data) =>
  await client.put(`/contributors/${id}`, data);

export const CreateContributor = async (data) =>
  await client.post('/contributors', data);

export const DeleteContributor = async (id) =>
  await client.delete(`/contributors/${id}`);

export const GetContributorsWithoutVehicles = async () =>
  await client.get('/contributors/without-vehicles');

export const GetContributorsWithoutTeams = async () =>
  await client.get('/contributors/without-teams');

export const GetContributorsWithVehicles = async () =>
  await client.get('/contributors/with-vehicles');

export const GetContributorsWithTeams = async () =>
  await client.get('/contributors/with-teams');

export const CreateContributorUnavailability = async (id, data) =>
  await client.post(`/contributors/${id}/unavailability`, data);

export const GetContributorUnavailability = async (id) =>
  await client.get(`/contributors/${id}/unavailability`);

export const GetContributorUnavailabilityDate = async (id, data) => {
  return await client.put(`/contributors/${id}/unavailability/date`, data);
};

export const DeleteContributorUnavailability = async (contributorId, data) =>
  await client.put(
    `/contributors/${contributorId}/unavailability/delete`,
    data
  );

export const GetContributorEvents = async (
  contributorId,
  currentPage,
  limit,
  filters,
  sorter
) =>
  await client.get(
    `/contributors/${contributorId}/events/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );
