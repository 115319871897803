import styled from 'styled-components';
import { Steps } from 'antd';
import { headerFormSize } from '../../styles/Variables';
const { Step } = Steps;

export const StyledContainer = styled.div`
  position: fixed;
  height: calc(100% - ${headerFormSize});
`;

export const StyledSteps = styled(Steps)`
  margin-top: 15px;
  padding-right: 5px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  width: 180px;
  z-index: 19;
  overflow: show;
  height: 100%;
  cursor: pointer;
`;

export const StyledStep = styled(Step)`
  height: 40px;
  flex: 0;
`;

export const EventFormContainer = styled.div`
  width: ${({ showSteps }) => (showSteps ? 'calc(100% - 250px)' : '100%')};
  max-width: ${({ showSteps }) => (showSteps ? '100%' : '600px')};
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${(p) => p.theme.primaryBackgroundColor};
  text-align: left;
  margin-left: ${({ showSteps }) => (showSteps ? '220px' : '0px')};
`;

export const StyledLastSection = styled.div`
  height: calc(100vh - ${headerFormSize});
`;
