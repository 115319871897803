import styled from 'styled-components';

export const DashboardContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 5px;
  text-align: left;
`;

export const DashboardTitle = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const DashboardSubtitle = styled.div`
  width: 100%;
  color: black;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
`;

export const DashboardUserContainer = styled.div`
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
`;
