import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  change,
  FormSection,
  formValueSelector,
  arrayRemove
} from 'redux-form';
import { Popconfirm, Icon, Row, Col } from 'antd';
import Table from '../../../../components/generic/table/Table';
import { TableButton } from '../../../../styles/BasicStyles';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import { GetAllTeams } from '../../../../infra/requests/TeamsRequests';
import TeamsExtendedTable from '../components/TeamsExtendedTable';
import moment from 'moment';

let TeamsTable = ({
  fields,
  objectName,
  dispatch,
  teams,
  vehicles,
  assemblyStartDate,
  assemblyEndDate,
  disassemblyStartDate,
  disassemblyEndDate
}) => {
  const [allTeams, setAllTeams] = useState([]);
  const [hasFetchTeams, setHasFetchTeams] = useState(false);

  useEffect(() => {
    async function fetchAllTeams() {
      const teamsRequest = await GetAllTeams();
      setAllTeams(teamsRequest.data);
      setHasFetchTeams(true);
    }
    if (!hasFetchTeams) {
      fetchAllTeams();
    }
  }, [hasFetchTeams]);

  const execAfterChangeTeam = (index, value) => {
    const selected = allTeams.find((team) => team._id === value);
    const newVehicles = [...vehicles];

    // check if the team was changed.
    const currentTeam = teams[index];
    if (currentTeam._id !== '') {
      // there was already a team selected, remove the inserted vehicles.
      currentTeam.contributors.forEach((teamContributor) => {
        vehicles.forEach((vehicle, vehicleIndex) => {
          if (teamContributor._id === vehicle.contributor._id) {
            // remove the vehicle at index
            newVehicles.splice(index, 1);
          }
        });
      });
    }

    const contributors = selected.contributors.map((team) => {
      if (team.contributor.vehicles.length > 0) {
        team.contributor.vehicles.forEach((vehicle) => {
          newVehicles.unshift({
            vehicle: {
              _id: vehicle._id,
              registration: vehicle.registration,
              brand: vehicle.brand,
              category: vehicle.category
            },
            contributor: {
              _id: vehicle.contributor._id,
              name: vehicle.contributor.name
            }
          });
        });
      }
      return team.contributor;
    });

    dispatch(
      change('manage_events_form', `teams[${index}]`, {
        _id: selected._id,
        team: { _id: selected._id, name: selected.name },
        contributors
      })
    );
    dispatch(change('manage_events_form', 'vehicles', newVehicles));
  };

  const columns = [
    {
      title: 'Equipas',
      dataIndex: 'teams',
      key: '_id',
      width: '70%',
      render: (value, item, index) => {
        const availableItems =
          item._id !== ''
            ? [
                item.team,
                ...allTeams.filter(
                  (team) => !teams.some((rowTeam) => rowTeam._id === team._id)
                )
              ]
            : allTeams.filter(
                (team) => !teams.some((rowTeam) => rowTeam._id === team._id)
              );

        return (
          <FormSection key={index} name={`${objectName}[${index}].team`}>
            <Field
              component={SelectInput}
              name={'_id'}
              disabled={isTableDisabled()}
              execAfterChange={(value) => execAfterChangeTeam(index, value)}
              placeholder="Escolha a equipa..."
              data={availableItems}
            />
          </FormSection>
        );
      }
    },
    {
      title: 'Operações',
      render: (value, item, index) => {
        if (isTableDisabled()) return [];
        return (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover a equipa?"
                onConfirm={() => removeTeam(item, index)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        );
      }
    }
  ];

  const removeTeam = (item, index) => {
    const disabled = isTableDisabled();
    if (disabled) return;
    // if the contributor added a vehicle
    if (item.contributors && item.contributors.length > 0) {
      item.contributors.forEach((contributor) => {
        if (contributor.vehicles && contributor.vehicles.length > 0) {
          contributor.vehicles.forEach((contributorVehicle) => {
            const findIndex = vehicles.findIndex(
              (eventVehicles) =>
                eventVehicles.vehicle._id === contributorVehicle._id
            );
            if (findIndex >= 0) {
              dispatch(
                arrayRemove('manage_events_form', 'vehicles', findIndex)
              );
            }
          });
        }
      });
    }

    fields.remove(index);
  };

  const isTableDisabled = () => {
    const today = moment().startOf('day');
    if (
      (moment(assemblyStartDate).startOf('day').isSameOrBefore(today) &&
        moment(assemblyEndDate).startOf('day').isSameOrAfter(today)) ||
      (moment(disassemblyStartDate).startOf('day').isSameOrBefore(today) &&
        moment(disassemblyEndDate).startOf('day').isSameOrAfter(today))
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Row style={{ marginTop: '25px', marginBottom: '10px' }}>
        <BaseButton
          type="default"
          icon="plus"
          text="Adicionar campo"
          disabled={isTableDisabled()}
          onClick={() => {
            if (
              teams.length === 0 ||
              (teams.length > 0 && teams[0]._id !== '')
            ) {
              return fields.unshift({
                _id: '',
                team: { _id: '', name: '' },
                contributors: []
              });
            }
          }}
        />
      </Row>
      <Row style={{ marginTop: '25px', marginBottom: '10px' }}>
        <Col xs={24}>
          A data corrente esté entre a data de montagem e desmontagem
        </Col>
      </Row>
      <Row>
        <Table
          columns={columns}
          currentPage={1}
          pageSize={teams.length}
          loading={false}
          rows={teams}
          rowKey={'_id'}
          showHeader={true}
          expandRowByClick={true}
          expandedRowRender={(record, tableIndex) =>
            record.contributors.length > 0 ? (
              <TeamsExtendedTable
                disabled={isTableDisabled()}
                record={record}
                tableIndex={tableIndex}
              />
            ) : (
              []
            )
          }
          emptyIcon="solution"
          emptyText={'Não existe nenhuma equipa'}
          total={teams.length}
        />
      </Row>
    </>
  );
};

const selector = formValueSelector('manage_events_form');

TeamsTable = connect((state) => {
  const {
    teams,
    vehicles,
    assemblyStartDate,
    assemblyEndDate,
    disassemblyStartDate,
    disassemblyEndDate
  } = selector(
    state,
    'hasFurniture',
    'teams',
    'vehicles',
    'assemblyStartDate',
    'assemblyEndDate',
    'disassemblyStartDate',
    'disassemblyEndDate'
  );
  return {
    teams,
    vehicles,
    assemblyStartDate,
    assemblyEndDate,
    disassemblyStartDate,
    disassemblyEndDate
  };
})(TeamsTable);

export default TeamsTable;
