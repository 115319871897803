export const EventBuildTypes = [
  { _id: 'modular', name: 'Modular' },
  { _id: 'carpentry', name: 'Carpintaria' }
];

export const getEventPlateType = (value) => {
  switch (value) {
    case 'modular':
      return 'Modular';
    case 'carpentry':
      return 'Carpintaria';
    default:
      break;
  }
};
