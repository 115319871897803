import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { Popconfirm, Icon, Row } from 'antd';
import Table from '../../../../components/generic/table/Table';
import { TableButton, SectionTitle } from '../../../../styles/BasicStyles';
import TextInput from '../../../../components/generic/inputs/TextInput';
import NumberInput from '../../../../components/generic/inputs/NumberInput';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import { connect } from 'react-redux';

let ExternalMaterialsTable = ({ fields, objectName, externalMaterials }) => {
  const columns = [
    {
      title: 'Referência',
      dataIndex: 'reference',
      render: (value, item, index) => (
        <Field
          component={TextInput}
          type="text"
          name={`${objectName}[${index}].reference`}
        />
      )
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (value, item, index) => (
        <Field
          component={TextInput}
          type="text"
          name={`${objectName}[${index}].description`}
        />
      )
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      render: (value, item, index) => (
        <Field
          component={NumberInput}
          name={`${objectName}[${index}].quantity`}
        />
      )
    },
    {
      title: 'Operações',
      render: (value, item, index) => (
        <React.Fragment>
          <TableButton error onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              placement="topRight"
              title="Tem a certeza que quer remover o material externo?"
              onConfirm={() => fields.remove(index)}
            >
              <Icon style={{ marginRight: 10 }} type="delete" />
              {'Remover'}
            </Popconfirm>
          </TableButton>
        </React.Fragment>
      )
    }
  ];

  return (
    <>
      <SectionTitle>Materiais externos</SectionTitle>
      <Row style={{ marginBottom: '10px' }}>
        <BaseButton
          type="default"
          icon="plus"
          text="Adicionar campo"
          onClick={() => {
            if (
              externalMaterials.length === 0 ||
              (externalMaterials.length > 0 &&
                externalMaterials[0].reference !== '')
            ) {
              fields.unshift({ reference: '' });
            }
          }}
        />
      </Row>
      <Row>
        <Table
          columns={columns}
          currentPage={1}
          pageSize={externalMaterials.length}
          loading={false}
          rows={externalMaterials}
          showHeader={true}
          emptyIcon="solution"
          emptyText={'Não existe nenhum material externo'}
          total={externalMaterials.length}
          rowKey={(record, index) => index}
        />
      </Row>
    </>
  );
};

const selector = formValueSelector('manage_events_form');

ExternalMaterialsTable = connect((state) => {
  const { externalMaterials } = selector(
    state,
    'hasFurniture',
    'externalMaterials'
  );
  return {
    externalMaterials
  };
})(ExternalMaterialsTable);

export default ExternalMaterialsTable;
