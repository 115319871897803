import React, {useState} from 'react'
import {Row, Col} from 'antd'
import {Modal, Button} from 'antd'
import {BaseForm, SectionTitle} from '../../styles/BasicStyles'
import {
  IgnoreAlert,
  DeleteAlert
} from '../../infra/requests/AlertsRequests'
import {getAlertsTypes} from '../../infra/services/utils/AlertsTypes'
import * as moment from 'moment'

const AlertsModal = ({
  selectedAlert,
  open,
  closeModal,
  closeModalAndUpdate
}) => {
  const [loading, setLoading] = useState(false)

  const onPressIgnore = async () => {
    try {
      setLoading(true)
      await IgnoreAlert(selectedAlert._id)
      setLoading(false)
      closeModalAndUpdate()
    } catch (error) {
      console.error(error)
    }
  }

  const onPressRemove = async (value) => {
    try {
      setLoading(true)
      await DeleteAlert(value._id)
      setLoading(false)
      closeModalAndUpdate()
    } catch (error) {
      console.error(error)
    }
  }

  const AlertDetailComponent = () => {
    switch (selectedAlert.type) {
      case 'low-materials': {
        return (
          <>
            <Row style={{marginTop: '10px'}}>
              <SectionTitle xs={24}>Detalhes do material</SectionTitle>
            </Row>
            <Row>
              <Col xs={24}>Nome: {selectedAlert.material.name}</Col>
            </Row>
            <Row>
              <Col xs={24}>
                Categoria:{' '}
                {selectedAlert.material.category &&
                  selectedAlert.material.category.name}
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                Subcategoria: {selectedAlert.material.subcategory}
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                Stock atual: {selectedAlert.material.quantity}
              </Col>
            </Row>
          </>
        )
      }
      case 'user-unavailability': {
        return (
          <>
            <Row style={{marginTop: '10px'}}>
              <SectionTitle xs={24}>Detalhes do colaborador</SectionTitle>
            </Row>
            <Row>
              <Col xs={24}>Nome: {selectedAlert.contributor.name}</Col>
            </Row>
            <Row>
              <Col xs={24}>Email: {selectedAlert.contributor.email}</Col>
            </Row>
            <Row>
              <Col xs={24}>
                Contacto: {selectedAlert.contributor.phone}
              </Col>
            </Row>
          </>
        )
      }
      default: {
        return []
      }
    }
  }

  return (
    <Modal
      visible={open}
      title='Informações do alerta'
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key='back' onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key='ignore'
          loading={loading}
          disabled={loading}
          onClick={onPressIgnore}
        >
          Ignorar
        </Button>
        // <Button
        //   key="remove"
        //   loading={loading}
        //   disabled={loading}
        //   onClick={onPressRemove}
        // >
        //   Remover
        // </Button>
      ]}
    >
      <BaseForm>
        <Row>
          <Col xs={24}>Tipo: {getAlertsTypes(selectedAlert.type)}</Col>
        </Row>
        <Row>
          <Col xs={24}>
            Inicio da incidência:{' '}
            {moment(selectedAlert.startDate).format('DD-MM-YYYY')}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            Fim da incidência:{' '}
            {selectedAlert.endDate
              ? moment(selectedAlert.endDate).format('DD-MM-YYYY')
              : 'Indefinida'}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>Mensagem: {selectedAlert.message}</Col>
        </Row>
        {AlertDetailComponent()}
      </BaseForm>
    </Modal>
  )
}

export default AlertsModal
