import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, DateInputField } from './InputStyles';
import moment from 'moment';

const DateInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  afterChangeDate
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;
  const onChangeDate = (moment) => {
    const date = moment ? moment.utc() : null;
    input.onChange(date);
    if (typeof afterChangeDate === 'function') {
      afterChangeDate(date);
    }
  };
  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <DateInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={dateFormat || 'DD-MM-YYYY'}
        defaultValue={input.value ? moment(input.value).utc() : null}
        onChange={onChangeDate}
        value={input.value ? moment(input.value).utc() : null}
      />
    </InputDiv>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default DateInput;
