import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import * as moment from 'moment';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetContributors,
  DeleteContributor
} from '../../infra/requests/ContributorsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ContributorsFilters from './ContributorsFilters';
import { getQuerySortName } from '../../infra/services/utils/QuerySortTypes';
import { getDepartment } from '../../infra/services/utils/Departments';

class ContributorsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    sorter: '',
    loading: false,
    columns: [
      {
        title: 'Código',
        sorter: true,
        dataIndex: 'code'
      },
      {
        title: 'Nome',
        sorter: true,
        dataIndex: 'name'
      },
      {
        title: 'Email',
        sorter: true,
        dataIndex: 'email'
      },
      {
        title: 'Contacto',
        sorter: true,
        dataIndex: 'phone'
      },
      {
        title: 'Departamento',
        dataIndex: 'department',
        render: getDepartment
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => this.onPressCalendar(e, data)}>
              <Icon style={{ marginRight: 10 }} type="calendar" />
              {'Calendário'}
            </TableButton>
            <TableButton onClick={(e) => this.onPressEvents(e, data)}>
              <Icon style={{ marginRight: 10 }} type="carry-out" />
              {'Eventos'}
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover o colaborador?"
                onConfirm={() => this.removeContributor(data)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  onPressCalendar = (event, data) => {
    const { history } = this.props;
    event.stopPropagation();
    history.push(`/contributors/${data._id}/calendar`);
  };

  onPressEvents = (event, data) => {
    const { history } = this.props;
    event.stopPropagation();
    history.push(`/contributors/${data._id}/events`);
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/contributors/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeContributor = async (data) => {
    try {
      await DeleteContributor(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  renderRowClass = (record, index) => {
    const today = moment.utc();

    // if the date is less than 2 months
    if (
      today.clone().subtract(2, 'months').isBefore(record.firstDayAtCompany)
    ) {
      return 'table-recent-contributor';
    }

    // if the date is less than 1 year
    if (today.clone().subtract(1, 'year').isBefore(record.firstDayAtCompany)) {
      return 'table-junior-contributor';
    }

    // more than 1 year and less than 2
    if (today.clone().subtract(2, 'year').isBefore(record.firstDayAtCompany)) {
      return 'table-intermedium-contributor';
    }

    // if the date is more than 1 year
    return 'table-senior-contributor';
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters, sorter } = this.state;
      const result = await GetContributors(
        currentPage,
        pageSize,
        filters,
        sorter
      );
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortObject = JSON.stringify({
      [sorter.field]: getQuerySortName(sorter.order)
    });
    this.setState({ sorter: sortObject }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Colaboradores</PageTitle>
            {'Colaboradores'}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/contributors/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <ContributorsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            rowClassName={this.renderRowClass}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum registo de colaboradores'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            onSortChange={this.handleTableChange}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ContributorsPage;
