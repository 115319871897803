import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import { GetUsers, DeleteUser } from '../../infra/requests/UsersRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { getUserPermission } from '../../infra/services/utils/Permissions';
import UsersFilters from './UsersFilters';

class UsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Permissão',
        dataIndex: 'permission',
        render: getUserPermission
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover o utilizador?"
                onConfirm={() => this.removeUser(data)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeUser = async (data) => {
    try {
      // todo: remove user
      await DeleteUser(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetUsers(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Utilizadores</PageTitle>
            {'Utilizadores'}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/users/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não há utilizadores'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default UsersPage;
