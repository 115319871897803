import React, { useEffect, useState } from 'react';
import {
  Field,
  FieldArray,
  formValueSelector,
  change,
  arrayRemove,
  arrayUnshift
} from 'redux-form';
import PropTypes from 'prop-types';
import { Popconfirm, Icon } from 'antd';
import { connect } from 'react-redux';
import Table from '../../../../components/generic/table/Table';
import { TableButton } from '../../../../styles/BasicStyles';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import { GetTeam } from '../../../../infra/requests/TeamsRequests';
import { getDepartment } from '../../../../infra/services/utils/Departments';
import { GetContributor } from '../../../../infra/requests/ContributorsRequests';

let TeamsExtendedTable = ({
  tableIndex,
  teams,
  vehicles,
  record,
  dispatch,
  disabled
}) => {
  const [hasFetchContributors, setHasFetchContributors] = useState(false);
  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    async function fetchTeamContributors() {
      const teamContributors = await GetTeam(teams[tableIndex].team._id);
      setContributors(
        teamContributors.data.contributors.map(
          (contributor) => contributor.contributor
        )
      );
      setHasFetchContributors(true);
    }
    if (!hasFetchContributors) {
      fetchTeamContributors();
    }
  }, [hasFetchContributors, tableIndex, teams]);

  const execAfterChangeContributor = async (index, value) => {
    const contributorRequest = await GetContributor(value);
    dispatch(
      change(
        'manage_events_form',
        `teams[${tableIndex}].contributors[${index}]`,
        contributorRequest.data
      )
    );
  };

  const removeContributor = async (item, index) => {
    if (disabled) return;
    // if the contributor added a vehicle
    if (item.vehicles && item.vehicles.length > 0) {
      item.vehicles.forEach((contributorVehicle) => {
        const findIndex = vehicles.findIndex(
          (eventVehicles) =>
            eventVehicles.vehicle._id === contributorVehicle._id
        );
        if (findIndex >= 0) {
          dispatch(arrayRemove('manage_events_form', 'vehicles', findIndex));
        }
      });
    }
    dispatch(
      arrayRemove(
        'manage_events_form',
        `teams[${tableIndex}].contributors`,
        index
      )
    );
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '60%',
      render: (value, item, index) => (
        <Field
          component={SelectInput}
          name={`teams[${tableIndex}].contributors[${index}].name`}
          placeholder="Escolha o colaborador..."
          execAfterChange={(value) => execAfterChangeContributor(index, value)}
          disabled={disabled}
          data={contributors.filter(
            (contributor) =>
              !teams[tableIndex].contributors.some(
                (teamContributor) => contributor._id === teamContributor._id
              )
          )}
        />
      )
    },
    {
      title: 'Código',
      dataIndex: 'code'
    },
    {
      title: 'Contacto',
      dataIndex: 'phone'
    },
    {
      title: 'Departamento',
      dataIndex: 'department',
      render: getDepartment
    },
    {
      title: '',
      dataIndex: 'vehicles',
      render: (value) => {
        return (
          <React.Fragment>
            <TableButton onClick={(e) => e.stopPropagation()}>
              {value?.length > 0 ? <Icon type="car" /> : undefined}
            </TableButton>
          </React.Fragment>
        );
      }
    },
    {
      title: '',
      render: (value, item, index) => {
        if (disabled) return [];
        return (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer remover o colaborador?"
                onConfirm={() => removeContributor(item, index)}
              >
                <Icon style={{ marginRight: 10 }} type="delete" />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <>
      <BaseButton
        type="default"
        icon="plus"
        text="Adicionar campo"
        style={{ marginBottom: '10px' }}
        disabled={disabled}
        onClick={() => {
          const index = teams.findIndex(
            (element) => element._id === record._id
          );
          const teamContributors = teams[index].contributors;
          if (
            teamContributors.length === 0 ||
            (teamContributors.length > 0 && teamContributors[0]._id !== '')
          ) {
            dispatch(
              arrayUnshift(
                'manage_events_form',
                `teams[${index}].contributors`,
                {
                  _id: ''
                }
              )
            );
          }
        }}
      />
      <FieldArray
        component={() => (
          <Table
            columns={columns}
            currentPage={1}
            pageSize={record.contributors.length}
            loading={false}
            rows={record.contributors}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum colaborador'}
            total={record.contributors.length}
            rowKey={(record) => record._id}
          />
        )}
        name="contributors"
      />
    </>
  );
};

TeamsExtendedTable.propTypes = {
  record: PropTypes.object.isRequired,
  tableIndex: PropTypes.number.isRequired,
  teams: PropTypes.array.isRequired
};

const selector = formValueSelector('manage_events_form');

TeamsExtendedTable = connect((state) => {
  const { teams, vehicles } = selector(
    state,
    'hasFurniture',
    'teams',
    'vehicles'
  );
  return {
    teams,
    vehicles
  };
})(TeamsExtendedTable);

export default TeamsExtendedTable;
