import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Button, Icon } from 'antd';

const MultiOptionButton = ({ buttons, title, icon, onPressChoice }) => {
  const menu = () => {
    return (
      <Menu onClick={onPressChoice}>
        {buttons.map((button) => (
          <Menu.Item key={button}>{button}</Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Dropdown overlay={menu}>
      <Button>
        {icon && <Icon type={icon} />} {title} <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

MultiOptionButton.propTypes = {
  buttons: PropTypes.array,
  title: PropTypes.string,
  onPressChoice: PropTypes.func
};

export default MultiOptionButton;
