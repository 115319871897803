import React, { Component } from 'react';
import { reduxForm, initialize, reset, formValueSelector } from 'redux-form';
import {
  GetEvent,
  UpdateEvent,
  CreateEvent,
  AddFile,
  RemoveFile
} from '../../infra/requests/EventsRequests';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { connect } from 'react-redux';
import { EventFormContainer } from './EventsStyles';
import { scroller } from 'react-scroll';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import StepsSlider from './manage_steps/components/StepsSlider';
import EventInformationSteps from './manage_steps/EventInformationSteps';
import EventDatesStep from './manage_steps/EventDatesStep';
import EventDetailedSteps from './manage_steps/EventDetailedSteps';
import BaseButton from '../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  name: 'required',
  company: 'required',
  salesman: 'required',
  contactName: 'required',
  contactEmail: 'required|email',
  contactPhone: 'required',
  addressLocal: 'required',
  addressHall: 'required',
  addressWard: 'required',
  addressNumber: 'required',
  assemblyStartDate: 'required|date',
  assemblyEndDate: 'required|date',
  startDate: 'required|date',
  endDate: 'required|date',
  disassemblyStartDate: 'required|date',
  disassemblyEndDate: 'required|date'
});

class ManageEventsPage extends Component {
  state = {
    isNew: false,
    loading: true,
    showSteps: window.innerWidth > 700,
    currentProgress: 0
  };

  componentDidMount = async () => {
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.listenToScroll);

    const {
      match: { params },
      dispatch
    } = this.props;
    if (params.id) {
      const result = await GetEvent(params.id);
      dispatch(initialize('manage_events_form', result.data));
      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        currentProgress: 10,
        loading: false
      });
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.listenToScroll);
  };

  updateDimensions = () => {
    this.setState({ showSteps: window.innerWidth > 700 });
  };

  listenToScroll = () => {
    this.setState({ scrollPosition: window.pageYOffset });
  };

  setCurrentActive = (index) => {
    this.setState({ currentProgress: index });
  };

  onSubmit = async (values, createNewAfter, viewDetailsAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch
      } = this.props;
      const { isNew } = this.state;

      const request = isNew
        ? await CreateEvent(values)
        : await UpdateEvent(params.id, values);

      if (request.success) {
        this.setState({ loading: false });
        if (viewDetailsAfter) {
          dispatch(reset('manage_events_form'));
          return history.push(`/events/${request.data._id}`);
        }
        if (createNewAfter) {
          dispatch(reset('manage_events_form'));
          return history.push('/events/add');
        } else {
          return history.push('/events');
        }
      }
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/events');
  };

  selectAndScrollTo = (selectedIndex) => {
    this.setState({ currentProgress: selectedIndex });
    return scroller.scrollTo(`anchor-${selectedIndex}`, {
      duration: 500,
      smooth: true,
      offset: -172
    });
  };

  setCurrentProgress = (index) => {
    this.setState({ currentProgress: index });
  };

  handleAddFile = async (file) => {
    try {
      const {
        match: { params }
      } = this.props;
      const payload = new FormData();
      payload.append('image', file);
      const result = await AddFile(params.id, payload);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  handleDeleteFile = async (id) => {
    try {
      const {
        match: { params }
      } = this.props;
      const result = await RemoveFile(params.id, id);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  renderDetailedSection = () => {
    const { isNew, currentProgress } = this.state;
    const {
      handleSubmit,
      hasPlatform,
      isOtherOptionSelected,
      hasElectricity,
      hasAdvertising,
      hasBlueprints,
      hasFurniture,
      teams
    } = this.props;
    if (isNew) {
      return (
        <div style={{ float: 'right', marginTop: '30px' }}>
          <BaseButton
            type="primary"
            icon="arrow-right"
            text="Customizar mais detalhes"
            onClick={handleSubmit((values) =>
              this.onSubmit(values, false, true)
            )}
          />
        </div>
      );
    } else {
      return (
        <EventDetailedSteps
          currentProgress={currentProgress}
          setCurrentProgress={this.setCurrentProgress}
          hasAdvertising={hasAdvertising}
          hasBlueprints={hasBlueprints}
          hasElectricity={hasElectricity}
          hasFurniture={hasFurniture}
          hasPlatform={hasPlatform}
          isOtherOptionSelected={isOtherOptionSelected}
          teams={teams}
          handleAddFile={this.handleAddFile}
          handleDeleteFile={this.handleDeleteFile}
        />
      );
    }
  };

  render() {
    const { handleSubmit, pristine, change } = this.props;
    const { isNew, loading, currentProgress, showSteps } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Eventos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        {showSteps && !isNew && (
          <StepsSlider
            currentProgress={currentProgress}
            selectAndScrollTo={this.selectAndScrollTo}
            setCurrentActive={this.setCurrentActive}
          />
        )}
        <EventFormContainer showSteps={showSteps && !isNew}>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <EventInformationSteps
              currentProgress={currentProgress}
              setCurrentProgress={this.setCurrentProgress}
            />
            <EventDatesStep
              currentProgress={currentProgress}
              setCurrentProgress={this.setCurrentProgress}
              change={change}
            />
            {this.renderDetailedSection()}
          </BaseForm>
        </EventFormContainer>
      </React.Fragment>
    );
  }
}

ManageEventsPage = reduxForm({
  form: 'manage_events_form',
  validate: validations
})(ManageEventsPage);

const selector = formValueSelector('manage_events_form');

ManageEventsPage = connect((state) => {
  const {
    hasPlatform,
    hasElectricity,
    hasAdvertising,
    hasBlueprints,
    hasFurniture,
    teams
  } = selector(
    state,
    'hasPlatform',
    'hasElectricity',
    'hasAdvertising',
    'hasBlueprints',
    'hasFurniture',
    'teams'
  );
  const isOtherOptionSelected = selector(state, 'platformMaterial') === 'other';
  return {
    hasPlatform,
    hasElectricity,
    hasAdvertising,
    isOtherOptionSelected,
    hasBlueprints,
    hasFurniture,
    teams
  };
})(ManageEventsPage);

export default ManageEventsPage;
