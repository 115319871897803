export const EventCategories = [
  'Evento',
  'Contacto',
  'Morada',
  'Datas',
  'Construção',
  'Materiais',
  'Equipas',
  'Veiculos',
  'Piso',
  'Teto',
  'Limpeza',
  'Eletricidade',
  'Moveis',
  'Plantas',
  'Publicidade',
  'Ficheiros'
];
