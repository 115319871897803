import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetAlerts,
  IgnoreAlert,
  DeleteAlert
} from '../../infra/requests/AlertsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import AlertsFilters from './AlertsFilters';
import { getQuerySortName } from '../../infra/services/utils/QuerySortTypes';
import AlertsModal from './AlertsModal';
import { getAlertsTableType } from '../../infra/services/utils/AlertsTypes';
import * as moment from 'moment';

class AlertsPage extends Component {
  state = {
    selectedAlert: {},
    showAlertModal: false,
    pageSize: 30,
    currentPage: 1,
    filters: '',
    sorter: '',
    loading: false,
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'type',
        sorter: true,
        render: getAlertsTableType
      },
      {
        title: 'Inicio da incidência',
        dataIndex: 'startDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Fim da incidência',
        dataIndex: 'endDate',
        render: (date) =>
          date ? moment(date).format('DD-MM-YYYY') : 'Data indefinida'
      },
      {
        title: '',
        render: (data) => {
          return (
            <React.Fragment>
              {!data.ignore && (
                <TableButton error onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title="Tem a certeza que quer ignorar o alerta?"
                    onConfirm={() => this.ignoreAlert(data)}
                  >
                    <Icon style={{ marginRight: 10 }} type="close" />
                    {'Ignorar'}
                  </Popconfirm>
                </TableButton>
              )}
            </React.Fragment>
          );
        }
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    this.setState({ selectedAlert: record, showAlertModal: true });
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  ignoreAlert = async (data) => {
    try {
      await IgnoreAlert(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters, sorter } = this.state;
      const result = await GetAlerts(currentPage, pageSize, filters, sorter);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortObject = JSON.stringify({
      [sorter.field]: getQuerySortName(sorter.order)
    });
    this.setState({ sorter: sortObject }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  openAddStockModal = (data) => {
    this.setState({ selectedAlert: data, isAddAlertOpen: true });
  };

  closeModalAndUpdate = async () => {
    await this.updateDataTable();
    this.setState({ showAlertModal: false });
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      showAlertModal,
      selectedAlert
    } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Alertas</PageTitle>
            {'Alertas'}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <AlertsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum registo de alertas'}
            total={total}
            rowKey={(record) => record._id}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            onSortChange={this.handleTableChange}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>

        <AlertsModal
          selectedAlert={selectedAlert}
          open={showAlertModal}
          closeModal={() => this.setState({ showAlertModal: false })}
          closeModalAndUpdate={this.closeModalAndUpdate}
        />
      </React.Fragment>
    );
  }
}

export default AlertsPage;
