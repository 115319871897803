import client from '../config/AxiosConfig';

export const GetTeam = async (id) => await client.get(`/teams/${id}`);

export const GetAllTeams = async (id) => await client.get('/teams');

export const GetTeams = async (currentPage, limit, filters, sorter) =>
  await client.get(
    `/teams/${currentPage}/${limit}?filter=${filters}&sorter=${sorter}`
  );

export const UpdateTeam = async (id, data) =>
  await client.put(`/teams/${id}`, data);

export const CreateTeam = async (data) => await client.post('/teams', data);

export const DeleteTeam = async (id) => await client.delete(`/teams/${id}`);

// team contributors
export const DeleteTeamContributor = async (id) =>
  await client.delete(`/teams-contributors/${id}`);

export const GetTeamContributors = async (id) =>
  await client.get(`/teams-contributors/${id}`);

export const CreateTeamContributors = async (data) =>
  await client.post('/teams-contributors', data);

export const GetTeamsPDF = async () =>
  await client.get('/teams/download-teams-contributors', {
    responseType: 'blob'
  });
