export const Departments = [
  { _id: 1, name: 'Gabinete Técnico' },
  { _id: 2, name: 'Armazém' },
  { _id: 3, name: 'Carpintaria' },
  { _id: 4, name: 'Pintores' },
  { _id: 5, name: 'Serralheiros' },
  { _id: 6, name: 'Publicidade / Lonas' },
  { _id: 7, name: 'Pré-Montagem' },
  { _id: 8, name: 'Móveis / Plantas' },
  { _id: 9, name: 'Tendas' },
  { _id: 10, name: 'Eletricista' },
  { _id: 11, name: 'Motoristas' }
];

export const getDepartment = (value) => {
  switch (value) {
    case 1:
      return 'Gabinete Técnico';
    case 2:
      return 'Armazém';
    case 3:
      return 'Carpintaria';
    case 4:
      return 'Pintores';
    case 5:
      return 'Serralheiros';
    case 6:
      return 'Publicidade / Lonas';
    case 7:
      return 'Pré-Montagem';
    case 8:
      return 'Móveis / Plantas';
    case 9:
      return 'Tendas';
    case 10:
      return 'Eletricista';
    case 11:
      return 'Motoristas';

    default:
      break;
  }
};
