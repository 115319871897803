import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';
import infoReducer from './Info/info.reducer';
import userReducer from './User/user.reducer';
import menuReducer from './Menu/menu.reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    localize: localizeReducer,
    info: infoReducer,
    user: userReducer,
    menu: menuReducer
  });

export default rootReducer;
