import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, SwitchField } from './InputStyles';

const SwitchInput = ({
  input,
  small,
  size,
  onPressEnter,
  label,
  first,
  placeholder,
  disabled
}) => {
  return (
    <InputDiv first={first}>
      <InputLabelDiv small={small}>{label}</InputLabelDiv>
      <SwitchField
        disabled={disabled}
        size={size}
        checked={input.value ? input.value : false}
        onChange={input.onChange}
        placeholder={placeholder}
      />
    </InputDiv>
  );
};

SwitchInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func
};

export default SwitchInput;
