import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { InputDiv, FileSection, FileDropSection } from './InputStyles';
import { Icon, Popconfirm } from 'antd';
import { AsyncForEach } from './../../../infra/services/async/AsyncFunctions';

const FilesInput = ({ fields, handleAdd, handleDelete, maxSize }) => {
  const [loading, setLoading] = useState(false);

  const onPressFile = async (fileId) => {
    try {
      window.open(`${process.env.REACT_APP_IMAGES_URL}/${fileId}`, '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = async (accepted) => {
    setLoading(true);
    await AsyncForEach(accepted, async (file) => {
      const { data, success } = await handleAdd(file);
      if (success) fields.push(data);
    });
    setLoading(false);
  };

  const removeFromList = async (id, index) => {
    if (handleDelete) await handleDelete(id);
    return fields.remove(index);
  };

  return (
    <InputDiv>
      {fields.getAll().map((file, index) => {
        return (
          <FileSection key={index}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => onPressFile(file._id)}
            >
              {file.name || file.filename || ''}
            </div>
            <Popconfirm
              placement={'topRight'}
              title="Tem a certeza que quer remover este ficheiro?"
              onConfirm={() => removeFromList(file._id, index)}
            >
              <Icon type="delete" />
            </Popconfirm>
          </FileSection>
        );
      })}
      <Dropzone onDrop={handleDrop} maxSize={maxSize}>
        {({ getRootProps, getInputProps }) => (
          <FileDropSection {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon type={loading ? 'loading' : 'plus'} />
            <div>
              {loading
                ? 'A carregar...'
                : 'Click ou arraste os ficheiros para aqui'}
            </div>
          </FileDropSection>
        )}
      </Dropzone>
    </InputDiv>
  );
};

export default FilesInput;
