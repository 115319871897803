import React, { Component } from 'react';
import { Field, reduxForm, initialize, reset } from 'redux-form';
import {
  GetVehicle,
  UpdateVehicle,
  CreateVehicle
} from '../../infra/requests/VehiclesRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { VehiclesCategoryTypes } from '../../infra/services/utils/VehiclesCategoryTypes';
import { GetContributorsWithoutVehicles } from '../../infra/requests/ContributorsRequests';
import ManageFormHeader from '../../components/base/ManageFormHeader';

const validations = FormValidator.make({
  registration: 'required',
  brand: 'required',
  category: 'required'
});

class ManageVehiclesPage extends Component {
  state = {
    isNew: false,
    loading: true,
    contributors: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    await this.updateContributors();
    const { contributors } = this.state;

    if (params.id) {
      const result = await GetVehicle(params.id);
      if (result.data.contributor) contributors.push(result.data.contributor);
      dispatch(
        initialize('manage_vehicles_form', {
          ...result.data,
          contributors,
          contributor: result.data.contributor?._id
        })
      );
      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  updateContributors = async () => {
    const { data } = await GetContributorsWithoutVehicles();
    this.setState({ contributors: data });
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch
      } = this.props;
      const { isNew } = this.state;
      if (!values.contributor || values.contributor === '') {
        values.contributor = null;
      }
      const request = isNew
        ? await CreateVehicle(values)
        : await UpdateVehicle(params.id, values);

      if (request.success) {
        this.setState({ loading: false });
        if (createNewAfter) {
          dispatch(reset('manage_vehicles_form'));
          return history.push('/vehicles/add');
        } else {
          return history.push('/vehicles');
        }
      }
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/vehicles');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, contributors } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Veículos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="registration"
              type="text"
              label="Matricula"
              placeholder="Escreva a matricula"
            />
            <Field
              component={TextInput}
              name="brand"
              type="text"
              label="Marca"
              placeholder="Escreva a marca"
            />
            <Field
              component={SelectInput}
              name="category"
              data={VehiclesCategoryTypes}
              label="Categoria"
              placeholder="Escolha o tipo de categoria..."
            />
            <Field
              component={SelectInput}
              allowClear={true}
              name="contributor"
              data={contributors}
              label="Colaborador"
              placeholder="Escolha o colaborador..."
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageVehiclesPage = reduxForm({
  form: 'manage_vehicles_form',
  validate: validations
})(ManageVehiclesPage);

export default ManageVehiclesPage;
