export const Permissions = [
  { _id: 1, name: 'Administrador' },
  { _id: 2, name: 'Comercial' }
];

export const getUserPermission = value => {
  switch (value) {
    case 1:
      return 'Administrador';
    case 2:
      return 'Comercial';

    default:
      break;
  }
};
