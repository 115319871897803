import client from '../config/AxiosConfig';

export const GetAlert = async (id) => await client.get(`/alerts/${id}`);

export const GetAlerts = async (currentPage, limit, filters) =>
  await client.get(`/alerts/${currentPage}/${limit}?filter=${filters}`);

export const UpdateAlert = async (id, data) =>
  await client.put(`/alerts/${id}`, data);

export const CreateAlert = async (data) => await client.post('/alerts', data);

export const DeleteAlert = async (id) => await client.delete(`/alerts/${id}`);

export const IgnoreAlert = async (id) =>
  await client.put(`/alerts/${id}/ignore`);
