import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SectionTitle
} from '../../styles/BasicStyles';
import {
  GetVehicleEvents,
  GetVehicle
} from '../../infra/requests/VehiclesRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import VehiclesEventsFilters from './VehiclesEventsFilters';
import { getQuerySortName } from '../../infra/services/utils/QuerySortTypes';
import * as moment from 'moment';

class VehiclesEventPage extends Component {
  state = {
    loading: false,
    vehicle: {},
    filters: '',
    columns: [
      {
        title: 'Nome',
        sorter: true,
        dataIndex: 'name'
      },
      {
        title: 'Inicio do evento',
        sorter: true,
        dataIndex: 'startDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Fim do evento',
        sorter: true,
        dataIndex: 'endDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Inicio da montagem',
        sorter: true,
        dataIndex: 'assemblyStartDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },

      {
        title: 'Fim da desmontagem',
        sorter: true,
        dataIndex: 'disassemblyEndDate',
        render: (date) => moment(date).format('DD-MM-YYYY')
      },

      {
        title: 'Local',
        sorter: true,
        dataIndex: 'addressLocal'
      }
    ],
    sorter: '',
    pageSize: 30,
    currentPage: 1,
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateVehicle();
    await this.updateVehiclesEvents();
  };

  updateVehicle = async () => {
    const {
      match: { params }
    } = this.props;

    if (params.id) {
      const result = await GetVehicle(params.id);
      this.setState({ vehicle: result.data });
    }
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/events/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateVehiclesEvents);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateVehiclesEvents);
  };

  updateVehiclesEvents = async () => {
    this.setState({ loading: true });
    try {
      const {
        match: { params }
      } = this.props;
      const { currentPage, pageSize, filters, sorter } = this.state;
      const result = await GetVehicleEvents(
        params.id,
        currentPage,
        pageSize,
        filters,
        sorter
      );
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortObject = JSON.stringify({
      [sorter.field]: getQuerySortName(sorter.order)
    });
    this.setState({ sorter: sortObject }, this.updateVehiclesEvents);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateVehiclesEvents);
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/vehicles');
  };

  render() {
    const {
      loading,
      columns,
      currentPage,
      total,
      pageSize,
      rows,
      vehicle
    } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Eventos</PageTitle>
            {`Veículos > ${vehicle.registration} > Eventos`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={this.updateVehiclesEvents}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <VehiclesEventsFilters queryChange={this.handleFilterChange} />
          <SectionTitle style={{ marginTop: 15 }}>Eventos</SectionTitle>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="solution"
            emptyText={'Não existe nenhum evento a utilizar o vehicle'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            onSortChange={this.handleTableChange}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default VehiclesEventPage;
