import {
  PrimaryColor,
  SecondaryColor,
  ThirdColor,
  PrimaryCTA,
  SecondaryCTA,
  AlertColor,
  InputBorderColor,
  PrimaryBorderColor,
  SecondaryBorderColor,
  MenuBorderColor,
  PrimaryBackgroundColor,
  SecondaryBackgroundColor,
  ThirdBackgroundColor
} from './Colors';

export default {
  //colors
  primaryColor: PrimaryColor,
  secondaryColor: SecondaryColor,
  thirdColor: ThirdColor,
  primaryCTA: PrimaryCTA,
  secondaryCTA: SecondaryCTA,

  inputBorderColor: InputBorderColor,
  inputFocusColor: InputBorderColor,
  inputErrorColor: AlertColor,

  primaryBorderColor: PrimaryBorderColor,
  secondaryBorderColor: SecondaryBorderColor,
  menuBorderColor: MenuBorderColor,

  primaryBackgroundColor: PrimaryBackgroundColor,
  secondaryBackgroundColor: SecondaryBackgroundColor,
  thirdBackgroundColor: ThirdBackgroundColor,
  //text size
  titleSize: '24px',
  sectionSize: '20px',
  textSize: '14px'
};
