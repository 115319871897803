import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Select, Spin, Popconfirm, Icon } from 'antd';
import { InputDiv, InputLabelDiv, StyledSelectInput } from './InputStyles';
import { AlertColor } from '../../../styles/Colors';
const Option = Select.Option;

const filterOption = (inputValue, option) => {
  if (option?.props?.children?.length > 0) {
    return (
      option?.props?.children[0]
        .toLowerCase()
        .indexOf(inputValue.toLowerCase()) > -1
    );
  }
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const SelectInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  first,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  asyncRemove,
  execAfterChange
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;
  const [mouseOver, setMouseOver] = useState(null);
  const hasAsyncRemove = typeof asyncRemove === 'function';

  const formatValue = (value) => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = async (value) => {
    input.onChange(value || '');
    if (typeof execAfterChange === 'function') {
      await execAfterChange(value);
    }
  };

  const onPressAsyncRemove = async (event, currentValue) => {
    event.preventDefault();
    event.stopPropagation();
    await asyncRemove(currentValue);
  };

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <StyledSelectInput
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundMessage}
        filterOption={filterOption}
        allowClear={allowClear}
        onSearch={fetchData}
        onChange={changeSelect}
        value={formatValue(input?.value)}
        optionLabelProp="label"
        showArrow={true}
        showSearch={true}
        loading={loading}
        error={showError ? 1 : 0}
      >
        {data.map((current, index) => (
          <Option
            key={current[dataKey]}
            disabled={current.disabled}
            label={
              translatable
                ? current[dataLabel][activeLanguage.code]
                : current[dataLabel]
            }
            onMouseEnter={() => (hasAsyncRemove ? setMouseOver(index) : null)}
            onMouseLeave={() => (hasAsyncRemove ? setMouseOver(null) : null)}
          >
            {translatable
              ? current[dataLabel][activeLanguage.code]
              : current[dataLabel]}
            {mouseOver === index && hasAsyncRemove && (
              <div
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                style={{
                  float: 'right',
                  textDecoration: 'underline',
                  color: AlertColor,
                  fontWeight: '100'
                }}
              >
                <Popconfirm
                  placement="topRight"
                  title="Pretende remover a categoria de materiais permanentemente?"
                  onConfirm={(event) => onPressAsyncRemove(event, current)}
                >
                  <Icon style={{ marginRight: 10 }} type="delete" />
                  Remover
                </Popconfirm>
              </div>
            )}
          </Option>
        ))}
      </StyledSelectInput>
    </InputDiv>
  );
};

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool
};

SelectInput.defaultProps = {
  data: [],
  allowClear: false,
  dataKey: '_id',
  dataLabel: 'name'
};

export default withLocalize(SelectInput);
