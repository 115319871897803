export const AlertsTypes = [
  { _id: 'low-materials', name: 'Falta de materiais' },
  { _id: 'contributor-unavailability', name: 'Colaborador indisponível' },
  { _id: 'contributor-validation', name: 'Validar colaborador' }
];

export const getAlertsTypes = (value) => {
  switch (value) {
    case 'low-materials':
      return 'Falta de materiais';
    case 'contributor-unavailability':
      return 'Colaborador indisponível';
    case 'contributor-validation':
      return 'Validar colaborador';

    default:
      break;
  }
};

export const getAlertsTableType = (value, fields) => {
  switch (value) {
    case 'low-materials':
      return `Alerta de stock - ${
        (fields.material && fields.material.name) || 'Material Indisponível'
      }`;
    case 'contributor-unavailability':
      return `Colaborador indisponível - ${
        (fields.contributor && fields.contributor.code) || 'Código Indisponível'
      } - ${
        (fields.contributor && fields.contributor.name) || 'Nome Indisponível'
      }`;
    case 'contributor-validation':
      return `Validar colaborador - ${
        (fields.contributor && fields.contributor.code) || 'Código Indisponível'
      } - ${
        (fields.contributor && fields.contributor.name) || 'Nome Indisponível'
      }`;
    default:
      break;
  }
};
